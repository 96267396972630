.customButton, .active {
  min-width: 4rem;
  width: 5.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.active{
  background-color: rgb(35,48,61);
  color: white;
  }