.box {
  display: block;
	margin: auto;
  max-width: 65rem;
	box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
	padding: 0.5rem;
	box-sizing: border-box;
	background-color: #f8f8f8;
}

.header, .row, .showML {
  display: flex;
  align-items: top;
  border-collapse: collapse;
  cursor: default;
  justify-content: left;
  font-size: 0.85rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

.showML {
  justify-content: center;
  vertical-align: text-top;
  /* width: 7rem; */
  color: #008abd;
  font-weight: 600;
  margin: auto;
  padding: 0.2rem 0.1rem;
  border-radius: 0.3rem;
}

.showML:hover {
  background-color: rgb(236, 243, 248);
}

.header {
  font-weight: 600;
  border-bottom: 1px solid #aaa;
  text-transform: uppercase;
}

.row:last-child {
  border-bottom: none;
}

.cell, .cell2, .checkbox {
  display: inline;
  padding: 0.5rem 0.7rem;
  text-align: left;
  border-right:1px dotted lightgrey;
}

.cell2, a {
  color: #008abd;
  cursor: pointer;
  text-decoration: none;
}

.cell:last-child, .cell2:last-child {
  border-right: none; 
}

.checkbox {
  margin: -0.1rem 0rem -1.2rem 0rem !important;
  padding: -20px !important;
  border: 0px solid red;
}


.title {
  width: 65rem;
  margin: 1.2rem auto 0.2rem auto;

  border: 0px solid red;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  padding: 1rem;
  box-sizing: border-box;
  font-weight: 500;
}

.baloon {
  width: 14rem;
  background-color: white;
  padding: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.85rem;
  transform: translate(-45%, -107%);
  box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(50,50,50,0.5);
  color: #555;
  font-weight: 400;
  text-align: left;
  border-radius: 0.4rem;
  box-sizing: border-box;
}

.TimeSeriesCard {
  display:inline-block;
  max-height: 500px;
  width: 90%;
  border: 0px solid green;
  /* margin: 20px; */
}

@media only screen and (min-width: 1281px) {
  .TimeSeriesCard {
    width: 45%;
  }
}