.button {
  border-radius: 0.3rem;
  padding: 0.2rem;
	margin: 0.2rem;
	width: 3.8rem;
	display: flex;
	justify-content: center;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid white;
}

.button:hover {
  border: 1px solid rgb(35,48,61);
  box-shadow: 0rem 0rem 0.3rem rgba(0,0,0,0.3);
}