.instructions {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: medium;
  border-radius: 1rem;
  background-color: rgba(255,255,255,0.95);
  box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
  font-family: 'Roboto', sans-serif;
  color: rgba(80,80,80,1);
  width: min(80%, 30rem);
  box-sizing: border-box; 
}

.info {
  position: absolute;
  right: 2rem;
  top: 3rem;
  font-size: large;
  border-radius: 50%;
  background-color: rgb(245,245,245);
  box-shadow: 0px 0px 0.5rem rgba(0,0,0,0.5);
  font-family: 'Roboto', sans-serif;
  color: rgba(80,80,80,1);
  width: 3rem;
  height: 3rem;
  box-sizing: border-box; 
}

.questionMark {
  width: 3rem;
}



 .box {
    display: block;
    margin: auto;
  }


  .questionnaire, .questionnaireUp {
    background-color: rgba(245,245,245,1);
    margin: auto;
    width: 100%;
    height:50%;
    border: 0px solid green;
    position: absolute;
    bottom: 0%;
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    padding: 4% 7% 7% 7%;
    box-sizing: border-box;
    font-family: "Roboto";
    font-size: large;
    border-radius: 1rem 1rem 0rem 0rem;
    visibility: hidden;
  }

  .questionnaireUp {
    visibility: visible;
    height: 50%;
  }


  .pageTitle {
    color: white;
    font-family: 'Fedra',sans-serif;
    font-size: smaller;
    word-wrap: none;
    font-weight: 400;
    position: absolute;
    top: 0%;
    right: 0%;
    background-color: #23303dbb;
    padding: 0.2rem 0.4rem;
    border-radius: 0% 0% 0% 0.3rem;
  }

  .pageSubtitle {
    color: #23303d; /*#c4c4c4;*/
    font-family: 'Fedra',sans-serif;
    font-size:1.25rem;
    word-wrap: none;
    font-weight: 400;
    position: absolute;
    top: 6%;
    left: 30%;
  }


  .checkbox {
    margin: -0.1rem 0rem -1.2rem 0rem !important;
    padding: -20px !important;
    border: 0px solid red;
  }

  .select, .textBox{
    font-family: "Roboto";
    font-size: normal;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
    border: 1px solid lightgrey;
    width: 100%;
    overflow: hidden;
		text-overflow: ellipsis;
    margin-bottom: 1rem;
  }

  .title {
    width: 65rem;
    margin: 1.2rem auto 0.2rem auto;

    border: 0px solid red;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    padding: 1rem;
    box-sizing: border-box;
    font-weight: 500;
  }

  .baloon {
    width: 14rem;
    background-color: white;
    padding: 10rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.85rem;
    transform: translate(-46%, -107%);
    box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(50,50,50,0.5);
    color: #555;
    font-weight: 400;
    text-align: left;
    /* border-radius: 0.4rem; */
    box-sizing: border-box;
  }

  .baloon:before {
    content:"";
    position:absolute;
    top:99%;
    right:45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }

  .questionIcon {
    width:3rem;
    margin-right: 0.7rem;
  }

  .question {
    text-align: left;
    font-size: medium;
    border: 0px solid green;
  }

  .questionBox {
    border: 0px solid red;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .buttonSection {
    display: flex;
    position: absolute;
    bottom: 2rem;
    width: 84%;
  }

  .instructions {
    position: absolute;
    border: 0px solid red;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: medium;
    border-radius: 1rem;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 80%;
    box-sizing: border-box; 
  }






/* ===================================================== */

@media only screen and  (orientation: portrait) and (min-width: 1001px) {

  .questionnaire, .questionnaireUp {
    background-color: rgba(245,245,245,1);
    margin: auto;
    width: 90%;
    height: calc(30% + 2rem);
    border: 0px solid green;
    position: relative;
    bottom: calc(30% + 2rem);
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    padding: 7% 7% 7% 7%;
    box-sizing: border-box;
    font-family: "Roboto";
    font-size: large;
    border-radius: 1rem 1rem 0rem 0rem;
    visibility: hidden;
  }

  .questionnaireUp {
    visibility: visible;
  }
}


@media only screen and  (orientation: landscape) and (min-width: 1001px){

  .questionnaire, .questionnaireUp {
    background-color: rgba(245,245,245,1);
    margin: auto;
    width: calc(min(40%, 30rem));
    height: 60%;
    border: 0px solid green;
    position: absolute;
    top: 50%;
    right: 7rem;
    transform: translate(0%, -50%);
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    padding: 2% 2.5%;
    box-sizing: border-box; 
    font-family: "Roboto";
    font-size: large;
    border-radius: 1rem 1rem 1rem 1rem;
    justify-content: center;
    visibility: hidden;
  }

  .questionnaireUp {
    visibility: visible;
  }
  
  .select, .textBox{
    font-family: "Roboto";
    font-size: medium;
    border-radius: 0.4rem;
    padding: 0.1rem 0.5rem;
    border: 1px solid lightgrey;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1rem;
  }

  .buttonSection {
    display: flex;
    position: absolute;
    bottom: 2rem;
    border: 0px solid red;
    width: 85%;
  }

  .info {
    position: absolute;
    left: 2rem;
    top: calc(100% - 2rem);
    transform: translateY(-100%);
    font-size: large;
    border-radius: 50%;
    background-color: rgb(245,245,245);
    box-shadow: 0px 0px 0.5rem rgba(0,0,0,0.5);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 3rem;
    height: 3rem;
    padding: 0.2rem;
    box-sizing: content-box; 
  }

  .questionMark {
    width: 3rem;
  }

  .questionBox {
    border: 0px solid red;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 1rem;
  }

  .question {
    text-align: center;
    font-size: medium;
    border: 0px solid green;
  }

}



@media only screen and  (orientation: portrait) and (max-width: 1000px){

  .info {
    position: absolute;
    border: 0px solid red;
    left: 1rem;
    top: calc(100% - 2.5rem);
    transform: translateY(-100%);
    font-size: large;
    border-radius: 50%;
    background-color: rgb(245,245,245);
    box-shadow: 0px 0px 0.5rem rgba(0,0,0,0.5);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 2.5rem;
    height: 2.5rem;
    box-sizing: border-box; 
    padding: 0.2rem;
    box-sizing: content-box;     
  }

  .questionMark {
    width: 2.5rem;
  }

}



@media only screen and  (orientation: landscape) and (max-width: 1000px){

  .questionnaire, .questionnaireUp {
    background-color: rgba(245,245,245,1);
    margin: auto;
    width: 40%;
    height: 100%;
    border: 0px solid green;
    position: absolute;
    bottom: 0%;
    right: 0rem;
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    padding: 2% 2.5%;
    box-sizing: border-box; 
    font-family: "Roboto";
    font-size: large;
    border-radius: 1rem 0rem 0rem 1rem;
    justify-content: center;
    visibility: hidden;
  }

  .questionnaireUp {
    visibility: visible;
  }
  
  .select, .textBox{
    font-family: "Roboto";
    font-size: normal;
    border-radius: 0.4rem;
    padding: 0.1rem 0.5rem;
    border: 1px solid lightgrey;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1rem;
  }

  .buttonSection {
    display: flex;
    position: absolute;
    bottom: 2rem;
    border: 0px solid red;
    width: 85%;
  }

  .info {
    position: absolute;
    left: 1.5rem;
    top: calc(100% - 2.5rem);
    transform: translateY(-100%);
    border-radius: 50%;
    background-color: rgb(245,245,245);
    box-shadow: 0px 0px 0.5rem rgba(0,0,0,0.5);
    width: 2.5rem;
    height: 2.5rem;
    box-sizing: content-box; 
    padding: 0.2rem;
    font-size: large;
  }

  .questionMark {
    width: 2.5rem;
  }

  .questionIcon {
    width:3rem;
    margin: 0.5rem auto;
  }

  .questionBox {
    border: 0px solid red;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 1rem;
  }

  .question {
    text-align: center;
    font-size: medium;
    border: 0px solid green;
  }

  .instructions {
    position: absolute;
    border: 0px solid red;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: medium;
    border-radius: 1rem;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 75%;
    box-sizing: border-box; 
  }

}
