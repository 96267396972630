.pageContent {
  padding: 0rem;
}

.mainContainer {
  margin: 0rem auto;
  text-align: justify;
  min-width: 500px; 
  max-width: 800px; 
  padding: 0rem 5.8rem;
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-size: 1rem;
  line-height: 1.4rem;
}

.question {
  background-color: #E4E6E8;
  border: 1px grey dashed;
  padding: 1rem 2rem;
  margin: 0.4rem 0rem 0.4rem 0rem;
  font-weight: 400;
  text-align: left;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.question:hover {
  background-color: #d8dadb;
  transition: all 0.2s ease-in-out;
}

.answerHide {
  font-family: "Roboto";
  max-height: 0rem;
  transition: all 0.3s;
  overflow-y: hidden;
  padding: 0rem 1rem 0rem 1rem;
  margin-bottom: 0rem;
}

.answerShow {
  font-family: "Roboto";
  max-height: 10rem;
  transition: all 0.6s;
  overflow-y: hidden;
  padding: 0rem 1rem 0rem 1rem;
  margin-bottom: 0rem;
}

.arrowRight, .arrowDown {

  width: 0; 
  height: 0; 
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-left: 0.5rem solid grey;
  transform: translate(-1.1rem, 0rem);
  transition: transform 0.3s;
}

.arrowDown {
  transform: translate(-1.1rem, 0.15rem) rotate(90deg);
  -webkit-transform: translate(-1.1rem, 0rem) rotate(90deg);
  transition: transform 0.3s;
}


@media only screen and (max-width: 1000px) {
  .pageContent {
    padding-top: 3rem;
    width: 100%;
  }

  .mainContainer {
    margin: 0rem auto;
    text-align: justify;
    min-width: 80%; 
    max-width: 90%; 
    padding: 0rem;
    font-family: "Open Sans", sans-serif; /* 'NexaLight' */
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .question {
    margin: 1rem 0rem 0.4rem 0rem;
    font-weight: 600;
    text-align: left;
    font-size: 1.1rem;
    cursor: pointer;
    transition: margin 0.7s ease-in-out;
  }
}