.checkBoxArea {
  flex-flow: column;
  border: 1px solid #bfbfbf;
  justify-content: flex-start;
  justify-items: left;
  height: 6.4rem;
  overflow-y: scroll;
  background-color: #fff;
  width: 20rem;
  padding: 0.3rem 0.5rem;
  margin: 0rem 0.5rem;
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif; /*'NexaLight' */
}