.postcode {
  position: absolute;
  top: 1.8rem;
  right: 1rem;
  border: 0.15rem solid #03B3EE;
  border-radius: 0.4rem;
  background-color: white;
  display: flex;
  box-sizing: border-box;
}

  .postcode:focus-within {
    box-shadow: 0rem 0rem 0.3rem rgba(0,0,0,0.5);
    border: 0.15rem solid transparent;
  }

.postcodeSearchIcon {
  width: 1.2rem;
  margin: 0.3rem 0.3rem 0.3rem 0.5rem;
}

.closeIcon {
  width: 1rem;
  margin: 0.5rem;
}

.input2 {
  font-size: 1rem;
  border: none;
  border-radius: 0rem 0.4rem 0.4rem 0rem;
  background-color: white;
  padding: 0.5rem;
  min-width: 5.5rem;
  max-width: 7.5rem;
}

  .input2:-webkit-autofill {
    color: red !important;
  }
