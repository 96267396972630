.visibleBox {
  width: 14rem; 
  height:14rem;
  box-shadow: 0rem 0rem 1rem  rgba(0,0,0,0.3);
  padding: 0rem;
  margin: 1rem;  
  overflow-y: hidden;
  flex-shrink: 0;
  border-radius: 0rem;;
}

.primary_content {
  display: flex;
  flex-direction: column;
  width:14rem;
  height: 28rem;
  overflow: hidden;
  transition: all 0.3s ease-out;
}

.primary_content:hover {
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);
  transition: all 0.3s ease-out;
}

.image {
  height: 30%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  pointer-events: none;
}

.title {
  font-size:0.9rem;
  font-weight:600; 
  line-height: 1.2rem; 
  border: 0px solid green; 
  margin: 0rem; 
  padding: 0.5rem 1rem;
  height: 20%;
  box-sizing: border-box;
  display:flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: #484F57;
  color: white;
  font-family: "Roboto-Light";
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.description {
  font-size:0.8rem;
  font-weight:100; 
  line-height: 1rem; 
  text-align: left;
  background-color: #484f57;
  color: white;
  margin: 0rem; 
  padding: 1rem;
  box-sizing: border-box;
  height:30%;
  font-family: "Roboto-Light";
  display:flex;
  align-items: center;

  /* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

.exploreBtn {
  text-shadow: none;
  font-family: Roboto;
  font-size: 0.8rem;
  padding: 0.6rem 1.5rem;
  box-sizing: border-box;
  margin: auto;
  border: 0px solid #FAB700;
  background-color: #253343;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  color: rgb(238, 237, 237);
}

.exploreBtn:hover {
  transition: all 0.3s;
  background-color: #FAB700;
  color: #23303D;
}