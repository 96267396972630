.NavBar, .NavBarHiddenRight {
  height: 100%;
  position: fixed;
  left: 0rem;
  top: 0rem;
  background-color: #253343; /* #253343;/*#23303D; /* rgb(228,228,228); */
  width: 13rem;
  visibility: visible;
  overflow-y: hidden;
  font-size: 1rem;
  -webkit-transition: left 0.5s; /* For Safari 3.1 to 6.0 */
  transition: left 0.5s;
  box-shadow:3px 0px 15px 0px rgba(0,0,0,0.3);
}

.NavBarItem, .NavBarItemSub {
  display: flex;
  text-align: left;
  height: 2.5rem;
  padding: 0.5rem 0rem 0.5rem 1rem;
  border-left: 0.3rem solid #253343;
  box-sizing: border-box;
  /* color: black; */
  text-decoration: none;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Roboto-Thin", sans-serif;
  font-weight: 300;
  /* color: black; */
  opacity: 0.7;
}

.NavBarItemSub {
  border-left: 0.3rem solid #34414e;
}

.NavBarItem:hover {
  opacity: 1;
}

.NavBarItemActive
 {
  display: flex;
  text-align: left;
  width:100%;
  box-sizing: border-box;
  border-left: 0.3rem solid #FFC000;
  text-decoration: none;
  color: black;
  opacity: 1;
}

.NavBarItemContainer {
  display: flex;
  text-align: left;
  height: 2.5rem;
  padding: 0.5rem 0rem 0.5rem 1rem;
  border-left: 0.3rem solid #253343;
  width:100%;
  box-sizing: border-box;
  color: black;
  text-decoration: none;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Roboto-Light", sans-serif;
  color: black;
  opacity: 0.7;
}


.NavBarItemIcon {
  margin: 0px;
  height: 25px;
}


.NavBarItemTitle {
  display: flex;
  padding-left: 1rem;
  font-family: "Roboto-Light", "Noto", sans-serif;
  cursor: pointer;
  text-align: left;
  width: 100%;
  word-wrap: none;
  color: white;
}


.Logo {
  margin: 0px;
  width: 40px;
  -webkit-transition: width 0.5s; /* For Safari 3.1 to 6.0 */
  transition: width 0.5s;
}

.arrowRight, .arrowDown {
  width: 0; 
  height: 0; 
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-left: 0.5rem solid lightgrey;
  transform: translate(-1.1rem, 0.15rem);
  transition: transform 0.3s;
}

.arrowDown {
  transform: translate(-1.1rem, 0.15rem) rotate(90deg);
  -webkit-transform: translate(-1.1rem, 0.15rem) rotate(90deg);
  transition: transform 0.3s;
}


@media only screen and (max-width: 1000px) {

  .NavBar,.NavBarHiddenRight {
    height: 100%;
    position: fixed;
    right: 13rem;
    top: 0rem;
    background-color: #253343; /*#f0f2f5;*/
    width: 13rem;
    visibility: visible;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    font-size: 1rem;
    -webkit-transition: left 0.5s; /* For Safari 3.1 to 6.0 */
    transition: left 0.3s;
    box-shadow:3px 0px 15px 0px rgba(0,0,0,0.5);
  }

  .NavBar::-webkit-scrollbar {
    display: none;
  }

  .NavBarHiddenRight::-webkit-scrollbar {
    display: none;
  }

  .NavBarHiddenRight {
    position: absolute;
    left: -14rem;
    transition: left 0.3s;
  }


.NavBarItem, .NavBarItemSub {
  display: flex;
  text-align: left;
  height: 2.5rem;
  padding: 0.5rem 0rem 0.5rem 1rem;
  border-left: 0.3rem solid #253343;
  box-sizing: border-box;
  color: black;
  text-decoration: none;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Roboto-Light", sans-serif;
  color: black;
  opacity: 0.7;
}


  .NavBarItemSub {
    border-left: 0.3rem solid #34414e;
  }
  

  .NavBarItemActive
  {
    display: flex;
    text-align: left;
    width:100%;
    box-sizing: border-box;
    border-left: 0.3rem solid #FFC000;
    text-decoration: none;
    color: black;
    opacity: 1;
  }


  .ShowMenu {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1rem;
		left: 1rem;
		border-radius: 50%;
    background-color: #253343;
    width: 3.5rem;
    height: 3.5rem;
    transition: left 0.5s;
  }

  .ShowMenuExpanded {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1rem;
		left: 4.65rem;
		border-radius: 50%;
    background-color: #253343;
    width: 3.5rem;
    height: 3.5rem;
    transition: left 0.5s;
  }

}
