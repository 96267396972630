.pageContent {
  padding: 0rem;
  width: 100%
}

.tc {
  margin: 0rem auto;
  text-align: justify;
  min-width: 500px; 
  max-width: 800px; 
  padding: 0rem 5.8rem;
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-size: 1rem;
  line-height: 1.4rem;
}


.quotation {
  padding: 1rem 2.5rem;
  border: 1px dashed grey; 
  background-color: rgb(228,230,232); 
  color: black;
  font-weight: 400;
  font-style: italic;
}


li {
  margin: 1rem 0rem 1rem 0rem;
  /* font-family: 'NexaLight',sans-serif; */
}

.navLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid lightgrey;
  border-radius: 0.2rem;
  padding: 0.5rem 0.7rem;
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 1000px) {

  .pageContent {
    padding-top: 3rem;
    width:100%;
  }

  .tc {
    margin: 0rem auto;
    text-align: justify;
    min-width: 80%; 
    max-width: 90%; 
    padding: 0rem;
    font-family: "Open Sans", sans-serif; /* 'NexaLight' */
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .quotation {
    padding: 0.7rem 1rem;
    border: 1px dashed grey; 
    background-color: rgb(228,230,232); 
    color: black;
    font-weight: 400;
    font-style: italic;
  }

  .navLink {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid lightgrey;
    border-radius: 0.2rem;
    padding: 0.5rem 0.7rem;
    text-decoration: none;
    color: black;
  }

}