.pageContent {
  padding: 0rem;
  width: 100%
}

.tc {
  margin: 0rem auto;
  text-align: justify;
  max-width: 50rem; 
  padding: 0rem 5.8rem;
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-size: 1rem;
  line-height: 1.4rem;
}


@media only screen and (max-width: 1000px) {

  .pageContent {
    padding-top: 3rem;
    width:100%;
  }

  .tc {
    margin: 0rem auto;
    text-align: justify;
    min-width: 80%; 
    max-width: 90%; 
    padding: 0rem;
    font-family: "Open Sans", sans-serif; /* 'NexaLight' */
    font-size: 1rem;
    line-height: 1.4rem;
  }
}