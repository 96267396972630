.control {
  text-align: left;
  font-size: 0.95rem;
  outline: none;
  font: inherit;
  /* font-family: "Roboto", sans-serif; */
  padding: 0.31rem 0.62rem;
  box-sizing: border-box;
  background-color: rgb(250, 250, 250);
  margin: 1rem;
  min-height: 1.87px;
  width: max(40%, 18rem);
  border: 1px solid lightgrey;
  border-radius: 0.3rem;
}


/* .Input {
  padding: 0rem 0.8rem;
  box-sizing: border-box;
  width: max(40%, 18rem);
  margin: 0rem auto;
} */



.InputElement {
  /* outline: none; */
  border: 1px solid lightgrey;
  border-radius: 0.3rem;
  font: inherit;
  padding: 0px 10px;
  width: max(40%, 18rem);
  box-sizing: border-box;
}

.InputElement:focus, .control:focus {
  border: 1px solid grey;
}

.Invalid, .control.invalid {
  border: 1px solid red;
  background-color: rgb(253, 232, 230);
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #c0c0c0;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #c0c0c0;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #c0c0c0;
}
:-moz-placeholder { /* Firefox 18- */
  color: #c0c0c0;
}