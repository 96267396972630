@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');

.card {
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  border: 0px solid red;
  display: inline-block;
  background-color: transparent;
  width: auto;
}

.cardContent {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  cursor: default;
  background-color: #f7f7f7;
  background-clip: padding-box;
  border: 1px solid lightgrey;
  border-radius: .3rem;
  outline: 0;
  box-shadow: 0px 0px 20px 0px #cccccc;
  box-sizing: content-box;
}

.cardHeader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .0em .5em;
  border: 1px solid #808080;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: #808080;
  color: black;
  vertical-align: "middle";
  position: relative;
}

.heading {
  margin: 0.5em;
  font-size: 1.0em;
  color: white; /* black; */
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.01em;
  font-weight: 300;
  white-space: nowrap;      /*keep text on one line */
  overflow: hidden;         /*prevent text from being shown outside the border */
  text-overflow: ellipsis;  /*cut off text with an ellipsis*/
}

.close {
  padding: 0em 0.2em;
  background-color: transparent;
  border: 0;
  float: right;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1em;
  color: #fff;
  text-shadow: 0 1px 0 #666;
  opacity: 0.8;
  cursor: pointer;
}

.close:hover {
  text-shadow: 0 1px 0 #000;
  opacity: 1; 
}

/* .cardDialog.cardNotify .cardBody {
  padding-top: 10rem;
  color: #616161;
} */

.cardBody {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: .7em 1em;
  font-family: "Roboto", sans-serif;
}

.cardFooter {
  align-content: center;
  position: relative;
  border-top: 1px solid #dee2e6;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.9em;
  padding: 0.7em 1em;
  max-height: 35px;
  color: #777;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3em;
  background-color: #efefef;
}
