.closeCross {
  position: absolute;
  top: 0.1rem;
  right: 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: grey;
}

.info {
  position: absolute;
  border: 0px solid red;
  right: 2rem;
  top: calc(100% - 2rem);
  transform: translateX(-100%);
  font-size: x-large;
  border-radius: 50%;
  background-color: rgb(245,245,245);
  box-shadow: 0rem 0rem 0.5rem rgba(0,0,0,0.5);
  font-family: 'Roboto', sans-serif;
  color: rgba(80,80,80,1);
  width: 3rem;
  height: 3rem;
  box-sizing: border-box;
}

.graph {
  width: 3rem;
}


@media only screen and (min-width: 1001px) {
  .mapContainer {
    height: calc(100% - 4rem);
    width: calc(100% - 8rem);
    border: 0.3rem solid rgb(248, 248, 248);
    margin: 2rem 4rem;
    position: absolute;
    touch-action: none;
    pointer-events: all;
    box-sizing: border-box;
    box-shadow: 0rem 0rem 1.25rem rgba(0, 0, 0, 0.2);
  }

  .baloon {
    transform: translate(-50%, calc(-100% - 1.1rem));
    background-color: white;
    padding: 1rem 0.2rem 0.5rem 0.2rem;
    font-family: 'Open Sans', sans-serif;
    font-size: small;
    box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(0, 0, 0, 0.3);
    color: #555;
    font-weight: 400;
    text-align: left;
    border-radius: 0.4rem;
    box-sizing: border-box;
    position: relative;
    max-width: 14rem;
  }

  .baloon:before {
    content: '';
    position: absolute;
    top: 98%;
    right: 50%;
    transform: translate(50%, 0%);
    width: 0;
    height: 0;
    border-left: 0.63rem solid transparent;
    border-right: 0.63rem solid transparent;
    border-top: 0.63rem solid white;
  }

  .selectMetric {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 4rem;
    font-family: 'Roboto';
    font-size: normal;
    padding: 0.4rem 1rem;
    border: 2px solid rgb(140, 192, 244);
    border-radius: 0.4rem;
    background-color: white;
    /* box-shadow: 0px 0px 0.5rem 0.2rem rgba(0,0,0,0.3); */
  }

  .showLegend {
    position: absolute;
    bottom: 4rem;
    right: 6rem;
    width: 1rem;
    height: 1rem;
    border: 1px solid green;
    padding: 1.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 0px solid lightgrey;
    box-shadow: 0px 0px 0.5rem 0.2rem rgba(0, 0, 0, 0.3);
  }

  .legend {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    bottom: 9rem;
    right: 6rem;
    transform: translate(0%, 0%);
    background-color: white;
    border-radius: 0.4rem;
    padding: 1.5rem 0.8rem 0.8rem 0.8rem;
    width: 13rem;
    font-size: normal;
    border: 0px solid lightgrey;
    box-shadow: 0px 0px 0.5rem 0.2rem rgba(0, 0, 0, 0.3);
  }

  .info {
    position: absolute;
    left: 2rem;
    top: calc(100% - 2rem);
    transform: translateY(-100%);
    font-size: x-large;
    border-radius: 50%;
    background-color: rgb(245,245,245);
    box-shadow: 0rem 0rem 0.5rem rgba(0,0,0,0.5);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 3rem;
    height: 3rem;
    padding: 0.2rem;
    box-sizing: content-box;
  }

  .graph {
    width: 3rem;
  }

}


@media only screen and (max-width: 1000px) {
  .mapContainer {
    height: 100%;
    width: 100%;
    border: 0px solid rgb(248, 248, 248);
    margin: 0rem;
    position: absolute;
    touch-action: none;
    pointer-events: all;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }

  .baloon {
    background-color: white;
    padding: 1rem;
    font-family: 'Open Sans', sans-serif;
    font-size: small;
    transform: translate(-50%, calc(-100% - 1.1rem));
    box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(0, 0, 0, 0.3);
    color: #555;
    font-weight: 400;
    text-align: left;
    border-radius: 0.4rem;
    box-sizing: border-box;
    position: relative;
  }

  .baloon:before {
    content: '';
    position: absolute;
    top: 98%;
    right: 50%;
    transform: translate(50%, 0%);
    width: 0;
    height: 0;
    border-left: 0.63rem solid transparent;
    border-right: 0.63rem solid transparent;
    border-top: 0.63rem solid white;
  }

  .selectMetric {
    position: absolute;
    bottom: 1.4rem;
    left: 50%;
    transform: translate(-50%, 0%);
    font-family: 'Roboto';
    font-size: normal;
    padding: 0.4rem 1rem;
    border: 2px solid rgb(140, 192, 244);
    border-radius: 0.4rem;
    width: calc(max(14rem, calc(100% - 16rem)));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: white;
  }

  .showLegend {
    position: absolute;
    bottom: 1.4rem;
    right: 2rem;
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid lightgrey;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .legend {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    bottom: 5rem;
    right: 2rem;
    transform: translate(0%, 0%);
    background-color: white;
    border-radius: 0.4rem;
    padding: 1.5rem 0.8rem 0.8rem 0.8rem;
    width: 11rem;
    font-size: small;
    border: 1px solid lightgrey;
  }

  .info {
    position: absolute;
    border: 0px solid red;
    left: 1rem;
    top: calc(100% - 2.5rem);
    transform: translateY(-100%);
    font-size: x-large;
    border-radius: 50%;
    background-color: rgb(245,245,245);
    box-shadow: 0rem 0rem 0.5rem rgba(0,0,0,0.5);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.2rem;
    box-sizing: content-box;
  }

  .graph {
    width: 2.5rem;
  }

}
