.pageContent {
  padding: 0rem;
  margin: 0rem auto;
  width: 70%;
}


@media only screen and (max-width: 1000px) {

  .pageContent {
    padding: 3rem 0rem 3rem 0rem;
    margin: 0rem auto;
    width: 100%;
  }

}