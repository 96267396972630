.pageContent {
  padding: 3rem 0rem;
  margin: 0rem auto;
  width: 70%;
}


.updatePsswdForm {
  padding: 0rem 0rem 1.5rem 0rem;
  width: 17rem;
  margin: auto;
}

.Hide {
  display: none;
}


@media only screen and (max-width: 1000px) {
  .pageContent {
    padding: 3rem 0rem 3rem 0rem;
    margin: 0rem auto;
    width: 100%;
  }
}