.bar {
  display: flex;
  position: absolute;
  overflow-x: visible;
  white-space: nowrap;
}

.closeCross {
  position: absolute;
  top: 0.5rem;
  right: 0.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  line-height: 1.5rem;
  color: grey;
}

.chartArea {
  display:flex;
  flex-flow: column nowrap;
  position: absolute;
  align-items: flex-start;
  top: 50%;
  right: 50%;
  background-color: white;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  box-sizing: border-box;
  transition: transform  linear 0.3s, opacity linear 0.3s;
  border-left: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
}

.chartBox {
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  align-items: flex-end;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0.5rem rgba(0,0,0,0.5);
  width: calc(min(80%, 30rem));
  height: calc(min(80%, 20rem));
  box-sizing: border-box;
  overflow: hidden;
}