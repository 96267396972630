.ribbon {
  margin: auto;
	font-size: calc(min(10vw, 3rem));
  font-family: 'Fedra',sans-serif;
	font-weight: 500;
  color: white;
	padding: 2rem;
	background-color: rgba(35,48,61,0.4);
	width: 100%;
	text-shadow: 0.1rem 0.1rem 0.1rem black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  background-color: #253343;
  border-radius: 50%;
  padding: 1rem;
  width: 10rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center
}

.exploreBtn {
  text-shadow: none;
  font-family: "Roboto";
  font-size: 1rem;
  padding: 1rem 3rem;
  border: 0px solid #FAB700;
  background-color: #253343;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.exploreBtn:hover {
  transition: all 0.3s;
  background-color: #FAB700;
  color: #23303D;
}


@media only screen and (orientation: landscape) {

    .pageContent {
        background-size: cover;
        background-repeat: repeat;
        background-position: center;
        display: flex;      
        width: 100%;
        height: 100%; 
        background-image: url("../../imgs/SmartCity_Manchester.jpeg");
    }

}


@media only screen and  (orientation: portrait) {

    .pageContent {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 40% 50%;
        display: flex;
        width: 100%;
        height: 100%;
        background-image: url("../../imgs/SmartCity_Manchester.jpeg")
    }

}