.MainContent {
  position: absolute;
  top: 0rem;
  left: 13rem;
  box-sizing: border-box;
  padding: 0rem;
  background-color: inherit;
  height: calc(100% - 0rem);
  width: calc(100% - 13rem);
  /* touch-action: none; */
  border: 0px solid red;
}



@media only screen and (max-width: 1000px) {
  .MainContent {
    position: absolute;
    top: 0rem;
    left: 0rem;
    border: 0px solid red;
    padding: 0rem;
    width: 100%;
  }
}
