@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  background-color: #F0F0F0;
  height: 100%;
}

html {
  height: 100%;
  font-size: 15px;
  background-color: #f0f0f0;
}

button {
  text-shadow: none;
  font-family: "Roboto";
  font-size: 1rem;
  padding: 1rem 3rem;
  border: 0px solid #FAB700;
  background-color: #253343;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  color: white;
}

button:hover {
  transition: all 0.3s;
  background-color: #FAB700;
  color: #23303D;
}

button:disabled,
button[disabled] {
  border: 0px solid #999999;
  background-color: #cccccc;
  color: #666666;
}


/* input, select, textarea {
  border: 0px;
  border-bottom: 1px solid #23303d;
  background-color: rgb(250, 250, 250);
  margin: 0px;
  min-height: 30px;
  text-align: left;
  font-size: 0.95rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  padding: 5px 10px;
}

input:focus, textarea:focus {
  border-width: 3px;
  background-color: rgb(255, 251, 240);
  background-color: "inherit";
} */

.index__FormTitle__IzLEy{
  font-weight: bold;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  color: #23303d;
  border: 0px solid red;
  margin: 40px 0px 40px 0px;
} 

h1, h2 {
  font-weight: bold;
  font-size: 1.7rem;
  font-family: "Open Sans", sans-serif;
  color: #23303d;
  border: 0px solid red;
  margin: 2rem 0px 2rem 0rem;
  letter-spacing: 0.0em;
  font-weight: 600;
}

h2 {
  font-size: 1.54rem;
  margin-bottom: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

form {
  background-color: inherit;
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}

@font-face {
  font-family: "Fedra";
  src: url(/static/media/Fedra_Sans_Book.70680fb6.ttf) format("truetype");
}

@font-face {
  font-family: "NexaLight";
  src: url(/static/media/NexaLight.2898d8cc.woff) format("woff");
}

@font-face {
  font-family: "Roboto-Light";
  src: url(/static/media/Roboto-Light.01744b0f.ttf) format("truetype");
}
.App_App__2rPyS {
  text-align: center;
  font-family: "Roboto", sans-serif;
  height: 100%;
}
.LandingPage_ribbon__2_rZ9 {
  margin: auto;
	font-size: calc(min(10vw, 3rem));
  font-family: 'Fedra',sans-serif;
	font-weight: 500;
  color: white;
	padding: 2rem;
	background-color: rgba(35,48,61,0.4);
	width: 100%;
	text-shadow: 0.1rem 0.1rem 0.1rem black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LandingPage_logo__RHpp6 {
  background-color: #253343;
  border-radius: 50%;
  padding: 1rem;
  width: 10rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center
}

.LandingPage_exploreBtn__3047h {
  text-shadow: none;
  font-family: "Roboto";
  font-size: 1rem;
  padding: 1rem 3rem;
  border: 0px solid #FAB700;
  background-color: #253343;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.LandingPage_exploreBtn__3047h:hover {
  transition: all 0.3s;
  background-color: #FAB700;
  color: #23303D;
}


@media only screen and (orientation: landscape) {

    .LandingPage_pageContent__3J9TF {
        background-size: cover;
        background-repeat: repeat;
        background-position: center;
        display: flex;      
        width: 100%;
        height: 100%; 
        background-image: url(/static/media/SmartCity_Manchester.7a9062b8.jpeg);
    }

}


@media only screen and  (orientation: portrait) {

    .LandingPage_pageContent__3J9TF {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 40% 50%;
        display: flex;
        width: 100%;
        height: 100%;
        background-image: url(/static/media/SmartCity_Manchester.7a9062b8.jpeg)
    }

}
.NavBar_NavBar__ciXpB, .NavBar_NavBarHiddenRight__1MIIQ {
  height: 100%;
  position: fixed;
  left: 0rem;
  top: 0rem;
  background-color: #253343; /* #253343;/*#23303D; /* rgb(228,228,228); */
  width: 13rem;
  visibility: visible;
  overflow-y: hidden;
  font-size: 1rem; /* For Safari 3.1 to 6.0 */
  transition: left 0.5s;
  box-shadow:3px 0px 15px 0px rgba(0,0,0,0.3);
}

.NavBar_NavBarItem__2mvil, .NavBar_NavBarItemSub__3YtAp {
  display: flex;
  text-align: left;
  height: 2.5rem;
  padding: 0.5rem 0rem 0.5rem 1rem;
  border-left: 0.3rem solid #253343;
  box-sizing: border-box;
  /* color: black; */
  text-decoration: none;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Roboto-Thin", sans-serif;
  font-weight: 300;
  /* color: black; */
  opacity: 0.7;
}

.NavBar_NavBarItemSub__3YtAp {
  border-left: 0.3rem solid #34414e;
}

.NavBar_NavBarItem__2mvil:hover {
  opacity: 1;
}

.NavBar_NavBarItemActive__3hxyY
 {
  display: flex;
  text-align: left;
  width:100%;
  box-sizing: border-box;
  border-left: 0.3rem solid #FFC000;
  text-decoration: none;
  color: black;
  opacity: 1;
}

.NavBar_NavBarItemContainer__1wyeB {
  display: flex;
  text-align: left;
  height: 2.5rem;
  padding: 0.5rem 0rem 0.5rem 1rem;
  border-left: 0.3rem solid #253343;
  width:100%;
  box-sizing: border-box;
  color: black;
  text-decoration: none;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Roboto-Light", sans-serif;
  color: black;
  opacity: 0.7;
}


.NavBar_NavBarItemIcon__38xvi {
  margin: 0px;
  height: 25px;
}


.NavBar_NavBarItemTitle__sdQnh {
  display: flex;
  padding-left: 1rem;
  font-family: "Roboto-Light", "Noto", sans-serif;
  cursor: pointer;
  text-align: left;
  width: 100%;
  word-wrap: none;
  color: white;
}


.NavBar_Logo__2VqI- {
  margin: 0px;
  width: 40px; /* For Safari 3.1 to 6.0 */
  transition: width 0.5s;
}

.NavBar_arrowRight__21gdM, .NavBar_arrowDown__2E0Q5 {
  width: 0; 
  height: 0; 
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-left: 0.5rem solid lightgrey;
  -webkit-transform: translate(-1.1rem, 0.15rem);
          transform: translate(-1.1rem, 0.15rem);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.NavBar_arrowDown__2E0Q5 {
  transform: translate(-1.1rem, 0.15rem) rotate(90deg);
  -webkit-transform: translate(-1.1rem, 0.15rem) rotate(90deg);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}


@media only screen and (max-width: 1000px) {

  .NavBar_NavBar__ciXpB,.NavBar_NavBarHiddenRight__1MIIQ {
    height: 100%;
    position: fixed;
    right: 13rem;
    top: 0rem;
    background-color: #253343; /*#f0f2f5;*/
    width: 13rem;
    visibility: visible;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    font-size: 1rem; /* For Safari 3.1 to 6.0 */
    transition: left 0.3s;
    box-shadow:3px 0px 15px 0px rgba(0,0,0,0.5);
  }

  .NavBar_NavBar__ciXpB::-webkit-scrollbar {
    display: none;
  }

  .NavBar_NavBarHiddenRight__1MIIQ::-webkit-scrollbar {
    display: none;
  }

  .NavBar_NavBarHiddenRight__1MIIQ {
    position: absolute;
    left: -14rem;
    transition: left 0.3s;
  }


.NavBar_NavBarItem__2mvil, .NavBar_NavBarItemSub__3YtAp {
  display: flex;
  text-align: left;
  height: 2.5rem;
  padding: 0.5rem 0rem 0.5rem 1rem;
  border-left: 0.3rem solid #253343;
  box-sizing: border-box;
  color: black;
  text-decoration: none;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Roboto-Light", sans-serif;
  color: black;
  opacity: 0.7;
}


  .NavBar_NavBarItemSub__3YtAp {
    border-left: 0.3rem solid #34414e;
  }
  

  .NavBar_NavBarItemActive__3hxyY
  {
    display: flex;
    text-align: left;
    width:100%;
    box-sizing: border-box;
    border-left: 0.3rem solid #FFC000;
    text-decoration: none;
    color: black;
    opacity: 1;
  }


  .NavBar_ShowMenu__r_Zfy {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1rem;
		left: 1rem;
		border-radius: 50%;
    background-color: #253343;
    width: 3.5rem;
    height: 3.5rem;
    transition: left 0.5s;
  }

  .NavBar_ShowMenuExpanded__1pSq4 {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1rem;
		left: 4.65rem;
		border-radius: 50%;
    background-color: #253343;
    width: 3.5rem;
    height: 3.5rem;
    transition: left 0.5s;
  }

}

.MainContent_MainContent__3As7u {
  position: absolute;
  top: 0rem;
  left: 13rem;
  box-sizing: border-box;
  padding: 0rem;
  background-color: inherit;
  height: calc(100% - 0rem);
  width: calc(100% - 13rem);
  /* touch-action: none; */
  border: 0px solid red;
}



@media only screen and (max-width: 1000px) {
  .MainContent_MainContent__3As7u {
    position: absolute;
    top: 0rem;
    left: 0rem;
    border: 0px solid red;
    padding: 0rem;
    width: 100%;
  }
}

.Home_banner__ufqBB, .Home_banner2__2X2_o, .Home_banner3__3R0ez {
  margin: 1rem 5rem;
  /* display: flex;
  vertical-align: middle;
  align-items: center; */
  background-color: rgb(252, 208, 208);
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  border: 1px solid red;
  max-height: 4rem; /* For Safari 3.1 to 6.0 */
  transition: padding 0.5s, max-height 0.5s, margin 0.5s;
  overflow-y: hidden;
  box-sizing: border-box;
  /* color: red; */
}

.Home_banner2__2X2_o {
  margin: 0.3rem 5rem;
  padding: 0rem;
  border: 0px solid red;
  max-height: 0rem; /* For Safari 3.1 to 6.0 */
  transition: padding 0.5s, max-height 0.5s, margin 0.5s;
}

.Home_banner3__3R0ez {
  background-color: rgb(255,225,129);
  border: 1px solid rgb(160, 141, 56);
}

.Home_generalMap__3pyQA, .Home_AQIndexMap__26yTM {
  height: 50rem;
  margin: 1rem 5rem 2rem 5rem;
  border: 0.3rem solid rgb(248,248,248);
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
  position: relative;
  overflow: hidden;
  background-color: rgb(248,248,248);
}

.Home_controls__1SmuJ {
  position: absolute;
  top: 0rem;
  left: 1rem;
  padding: 1rem 0.3rem;
  margin: 0rem;
  display: flex;
  border: 0px solid lightgrey;
  border-radius: 0.3rem;
  box-sizing: border-box;
  align-items: center;
}

.Home_AQIndexMap__26yTM {
  border: 0rem solid rgb(248,248,248);
  height: 51rem;
}


.Home_box__3TGE8 {
  display: block;
  margin: auto;
  max-width: 65rem;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: #f8f8f8;
}

.Home_header__Om9nw, .Home_row__3wxgp, .Home_showML__2rUaa {
  display: flex;
  align-items: top;
  border-collapse: collapse;
  cursor: default;
  justify-content: left;
  font-size: 0.85rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

.Home_showML__2rUaa {
  justify-content: center;
  vertical-align: text-top;
  /* width: 7rem; */
  color: #008abd;
  font-weight: 600;
  margin: auto;
  padding: 0.2rem 0.1rem;
  border-radius: 0.3rem;
}

  .Home_showML__2rUaa:hover {
    background-color: rgb(236, 243, 248);
  }

.Home_header__Om9nw {
  font-weight: 600;
  border-bottom: 1px solid #aaa;
  text-transform: uppercase;
}

.Home_row__3wxgp:last-child {
  border-bottom: none;
}

.Home_cell__3dnap, .Home_cell2__3HwdP, .Home_checkbox__TstS3 {
  display: inline;
  padding: 0.5rem 0.7rem;
  text-align: left;
  border-right: 1px dotted lightgrey;
}

.Home_cell2__3HwdP, a {
  color: #008abd;
  cursor: pointer;
  text-decoration: none;
}

  .Home_cell__3dnap:last-child, .Home_cell2__3HwdP:last-child {
    border-right: none;
  }

.Home_checkbox__TstS3 {
  margin: -0.1rem 0rem -1.2rem 0rem !important;
  padding: -20px !important;
  border: 0px solid red;
}


.Home_title__2aOie {
  width: 65rem;
  margin: 1.2rem auto 0.2rem auto;
  border: 0px solid red;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  padding: 1rem;
  box-sizing: border-box;
  font-weight: 500;
}

.Home_baloon__1ibS4 {
  width: 14rem;
  background-color: white;
  padding: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.85rem;
  -webkit-transform: translate(-50%, calc(-100% - 3rem));
          transform: translate(-50%, calc(-100% - 3rem));
  box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(50,50,50,0.5);
  color: #555;
  font-weight: 400;
  text-align: left;
  border-radius: 0.4rem;
  box-sizing: border-box;
  position: relative;
}

  .Home_baloon__1ibS4:before {
    content: "";
    position: absolute;
    top: 99%;
    right: 45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }


.Home_baloon2__BA-oE {
  width: 14rem;
  background-color: white;
  padding: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.85rem;
  -webkit-transform: translate(-50%, -130%);
          transform: translate(-50%, -130%);
  box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(50,50,50,0.5);
  color: #555;
  font-weight: 400;
  text-align: left;
  border-radius: 0.4rem;
  box-sizing: border-box;
  position: relative;
}

  .Home_baloon2__BA-oE:before {
    content: "";
    position: absolute;
    top: 99%;
    right: 45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }


.Home_infoBox__3tEUJ, .Home_plotBox__2dpWB {
  position: relative;
  background-color: rgba(248,248,248,1);
  padding: 2rem 2rem;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  scroll-behavior: smooth;
  scroll-behavior: auto;
  overflow-y: scroll;
  border: 0px solid green;
  width: 15rem;
  pointer-events: all;
}

.Home_plotBox__2dpWB {
  height: 100%;
  border: 0px solid green;
  flex-grow: 1;
}

.Home_test__1e6sW {
  width: 15rem;
  height: 100%;
  background-color: rgba(248,248,248,1);
  padding: 1.5rem 1.2rem;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-behavior: auto;
  overflow-y: scroll;
  position: absolute;
  right: -0.3rem;
  top: 0rem;
  /* border: 1px solid red; */
}

.Home_statDescription__3yIPR {
  height: 10rem;
  scroll-behavior: smooth;
  scroll-behavior: auto;
  overflow-y: scroll;
  color: #555;
  font-weight: 400;
  font-size: small;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  /* border: 1px solid green; */
}

.Home_closeCross__1QsL- {
  opacity: 0.3;
}

  .Home_closeCross__1QsL-:hover {
    opacity: 0.6;
  }

.Home_infoBoxTitle__lh5me {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(80,80,80);
  margin-top: 0.5rem;
}

.Home_toggleBtn__3MWst {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: small;
  border: 0px solid lightgrey;
  border-radius: 1.5rem;
  margin: 0rem 0.35rem;
  padding: 0.2rem 0.8rem;
  background-color: #f5f5f5;
  /*box-shadow: 0.1rem 0.1rem 0.2rem rgba(0,0,0,0.4);*/
  box-shadow: 0rem 0rem 0.3rem rgba(0,0,0,0.5);
  border: 0px solid lightgrey;
}

  .Home_toggleBtn__3MWst:hover {
    border: 0px solid lightgrey;
    background-color: #ffffff;
  }


.Home_ActiveTab__1QQEk, .Home_InactiveTab__3QNqs {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3rem;
  width: 100%;
  height: 3rem;
  background-color: white;
  padding-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid white;
  border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-radius: 5px 5px 0px 0px;
}

.Home_InactiveTab__3QNqs {
  background-color: #f8f8f8;
  color: grey;
}


.Home_AQIndex__zvnKF {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px rgba(0,0,0,1);
  font-size: 1rem;
  font-weight: 600;
}

.Home_plotBtn__2n6k9 {
  height: 1.4rem;
  cursor: pointer;
  border-radius: 10%;
  padding: 0.3rem;
  background-color: #eee;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(220,220,220,1) rgba(248,248,248,1);
}

  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(248,248,248,1);
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(220,220,220,1);
    border-radius: 10px;
    border: 0px solid white;
  }

.Home_clusterMarker__11F5o {
  color: #fff;
  /*background: #1978c8;*/
  /*background-color: #23303D;*/
  background: rgba(90,90,90,0.6);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: background 0.2s;
}
  .Home_clusterMarker__11F5o:hover {
    background: #1978c8;
    background:  rgba(110,110,110,1);
    transition: background 0.2s;
  }

  .Home_infoContainer__1ga6R {
    display: flex;
    flex-flow: row;
    width: 90%;
    height: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border: 0px solid red;
    pointer-events: none;
  }




@media only screen and (max-width: 1000px) {
  .Home_infoBox__3tEUJ, .Home_plotBox__2dpWB {
    width: 100%;
    height: 50%;
    background-color: rgba(248,248,248,1);
    padding: 2rem 2rem;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    scroll-behavior: smooth;
    scroll-behavior: auto;
    overflow-y: scroll;
    position: absolute;
    left: 0rem;
    top: 50%;
    border-radius: 1rem 1rem 0rem 0rem;
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    pointer-events: all;
  }

  .Home_plotBox__2dpWB {
    width: 100%;
    height: calc(100% - 0rem);
    position: absolute;
    left: 0rem;
    top: 0rem;
    border-radius: 0rem;
    box-shadow: none;
    pointer-events: all;
  }

  .Home_infoContainer__1ga6R {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0rem;
    top: 0rem;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    border: 0px solid red;
    background-color: transparent;
    pointer-events: none;
  }
}

.queryControlsSimple_button__2U7bh {
  border-radius: 0.3rem;
  padding: 0.2rem;
	margin: 0.2rem;
	width: 3.8rem;
	display: flex;
	justify-content: center;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid white;
}

.queryControlsSimple_button__2U7bh:hover {
  border: 1px solid rgb(35,48,61);
  box-shadow: 0rem 0rem 0.3rem rgba(0,0,0,0.3);
}
.PostcodeSearch_postcode__1FpaN {
  position: absolute;
  top: 1.8rem;
  right: 1rem;
  border: 0.15rem solid #03B3EE;
  border-radius: 0.4rem;
  background-color: white;
  display: flex;
  box-sizing: border-box;
}

  .PostcodeSearch_postcode__1FpaN:focus-within {
    box-shadow: 0rem 0rem 0.3rem rgba(0,0,0,0.5);
    border: 0.15rem solid transparent;
  }

.PostcodeSearch_postcodeSearchIcon__3Qnvh {
  width: 1.2rem;
  margin: 0.3rem 0.3rem 0.3rem 0.5rem;
}

.PostcodeSearch_closeIcon__dpRfT {
  width: 1rem;
  margin: 0.5rem;
}

.PostcodeSearch_input2__wRVTT {
  font-size: 1rem;
  border: none;
  border-radius: 0rem 0.4rem 0.4rem 0rem;
  background-color: white;
  padding: 0.5rem;
  min-width: 5.5rem;
  max-width: 7.5rem;
}

  .PostcodeSearch_input2__wRVTT:-webkit-autofill {
    color: red !important;
  }

.Input_control__2MUEk {
  text-align: left;
  font-size: 0.95rem;
  outline: none;
  font: inherit;
  /* font-family: "Roboto", sans-serif; */
  padding: 0.31rem 0.62rem;
  box-sizing: border-box;
  background-color: rgb(250, 250, 250);
  margin: 1rem;
  min-height: 1.87px;
  width: max(40%, 18rem);
  border: 1px solid lightgrey;
  border-radius: 0.3rem;
}


/* .Input {
  padding: 0rem 0.8rem;
  box-sizing: border-box;
  width: max(40%, 18rem);
  margin: 0rem auto;
} */



.Input_InputElement__370y_ {
  /* outline: none; */
  border: 1px solid lightgrey;
  border-radius: 0.3rem;
  font: inherit;
  padding: 0px 10px;
  width: max(40%, 18rem);
  box-sizing: border-box;
}

.Input_InputElement__370y_:focus, .Input_control__2MUEk:focus {
  border: 1px solid grey;
}

.Input_Invalid__2CZH-, .Input_control__2MUEk.Input_invalid__2pI_C {
  border: 1px solid red;
  background-color: rgb(253, 232, 230);
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #c0c0c0;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #c0c0c0;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #c0c0c0;
}
:-moz-placeholder { /* Firefox 18- */
  color: #c0c0c0;
}
.Auth_pageContent__1vZpN {
  padding: 0rem;
  margin: 0rem auto;
  width: 70%;
}


@media only screen and (max-width: 1000px) {

  .Auth_pageContent__1vZpN {
    padding: 3rem 0rem 3rem 0rem;
    margin: 0rem auto;
    width: 100%;
  }

}
.UserAccount_pageContent__3H2XA {
  padding: 3rem 0rem;
  margin: 0rem auto;
  width: 70%;
}


.UserAccount_updatePsswdForm__R6fqk {
  padding: 0rem 0rem 1.5rem 0rem;
  width: 17rem;
  margin: auto;
}

.UserAccount_Hide__2tWY1 {
  display: none;
}


@media only screen and (max-width: 1000px) {
  .UserAccount_pageContent__3H2XA {
    padding: 3rem 0rem 3rem 0rem;
    margin: 0rem auto;
    width: 100%;
  }
}
.AboutUs_pageContent__17yth {
  padding: 0rem;
  width: 100%
}

.AboutUs_tc__3S7nh {
  margin: 0rem auto;
  text-align: justify;
  min-width: 500px; 
  max-width: 800px; 
  padding: 0rem 5.8rem;
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-size: 1rem;
  line-height: 1.4rem;
}


.AboutUs_quotation__2xVID {
  padding: 1rem 2.5rem;
  border: 1px dashed grey; 
  background-color: rgb(228,230,232); 
  color: black;
  font-weight: 400;
  font-style: italic;
}


li {
  margin: 1rem 0rem 1rem 0rem;
  /* font-family: 'NexaLight',sans-serif; */
}

.AboutUs_navLink__3VhiQ {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid lightgrey;
  border-radius: 0.2rem;
  padding: 0.5rem 0.7rem;
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 1000px) {

  .AboutUs_pageContent__17yth {
    padding-top: 3rem;
    width:100%;
  }

  .AboutUs_tc__3S7nh {
    margin: 0rem auto;
    text-align: justify;
    min-width: 80%; 
    max-width: 90%; 
    padding: 0rem;
    font-family: "Open Sans", sans-serif; /* 'NexaLight' */
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .AboutUs_quotation__2xVID {
    padding: 0.7rem 1rem;
    border: 1px dashed grey; 
    background-color: rgb(228,230,232); 
    color: black;
    font-weight: 400;
    font-style: italic;
  }

  .AboutUs_navLink__3VhiQ {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid lightgrey;
    border-radius: 0.2rem;
    padding: 0.5rem 0.7rem;
    text-decoration: none;
    color: black;
  }

}
.TermsAndConditions_pageContent__1it3l {
  padding: 0rem;
}

.TermsAndConditions_tc__1cYh4 {
  margin: 0rem auto;
  text-align: justify;
  min-width: 500px; 
  max-width: 800px; 
  padding: 0rem 5.8rem;
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-size: 1rem;
  line-height: 1.4rem;
}

ul {
  margin: 1rem 0rem 1rem -1.5rem;
  /* font-family: 'NexaLight',sans-serif; */
}

ol {
  margin: 1rem 0rem 1rem -1.5rem;
  /* font-family: 'NexaLight',sans-serif; */
}


@media only screen and (max-width: 1000px) {

  .TermsAndConditions_pageContent__1it3l {
    padding-top: 3rem;
  }

  .TermsAndConditions_tc__1cYh4 {
    margin: 0rem auto;
    text-align: justify;
    min-width: 80%; 
    max-width: 90%; 
    padding: 0rem;
    font-family: "Open Sans", sans-serif; /* 'NexaLight' */
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .TermsAndConditions_quotation__2azbQ {
    padding: 0.7rem 1rem;
    border: 1px dashed grey; 
    background-color: rgb(228,230,232); 
    color: black;
    font-weight: 400;
    font-style: italic;
  }

  .TermsAndConditions_navLink__17kLR {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid lightgrey;
    border-radius: 0.2rem;
    padding: 0.5rem 0.7rem;
    text-decoration: none;
    color: black;
  }

}
.PrivacyPolicy_pageContent__2eTRG {
  padding: 0rem;
}

.PrivacyPolicy_tc__3O4TH {
  margin: 0rem auto;
  text-align: justify;
  min-width: 500px; 
  max-width: 800px; 
  padding: 0rem 5.8rem;
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-size: 1rem;
  line-height: 1.4rem;
}

li {
  margin: 1rem 0rem 1rem 0rem;
  /* font-family: 'NexaLight',sans-serif; */
}


@media only screen and (max-width: 1000px) {
  .PrivacyPolicy_pageContent__2eTRG {
    padding-top: 3rem;
  }

  .PrivacyPolicy_tc__3O4TH {
    margin: 0rem auto;
    text-align: justify;
    min-width: 80%; 
    max-width: 90%; 
    padding: 0rem;
    font-family: "Open Sans", sans-serif; /* 'NexaLight' */
    font-size: 1rem;
    line-height: 1.4rem;
  }
}
.Deployments_box1__14PgN {
  display: block;
	margin: auto;
  max-width: 75rem;
  min-width: 41rem;
	padding: 0.5rem;
	box-sizing: border-box;
  border: 0px solid red;
	/* background-color: #f8f8f8; */
}

.Deployments_box__1nOPo {
  display: block;
	margin: auto;
  max-width: 65rem;
  min-width: 41rem;
	box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
	padding: 0.5rem;
	box-sizing: border-box;
	background-color: #f8f8f8;
}

.Deployments_header__28dBp, .Deployments_row__2ZjRW, .Deployments_showML__27uwN {
  display: flex;
  align-items: top;
  border-collapse: collapse;
  cursor: default;
  justify-content: left;
  font-size: 0.85rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

.Deployments_showML__27uwN {
  justify-content: center;
  vertical-align: text-top;
  /* width: 7rem; */
  color: #008abd;
  font-weight: 600;
  margin: auto;
  padding: 0.2rem 0.1rem;
  border-radius: 0.3rem;
}

.Deployments_showML__27uwN:hover {
  background-color: rgb(236, 243, 248);
}

.Deployments_header__28dBp {
  font-weight: 600;
  border-bottom: 1px solid #aaa;
  text-transform: uppercase;
}

.Deployments_row__2ZjRW:last-child {
  border-bottom: none;
}

.Deployments_cell__2qamw, .Deployments_cell2__rsLHD, .Deployments_checkbox__ccPtZ {
  display: inline;
  padding: 0.5rem 0.7rem;
  text-align: left;
  border-right:1px dotted lightgrey;
}

.Deployments_cell2__rsLHD, a {
  color: #008abd;
  cursor: pointer;
  text-decoration: none;
}

.Deployments_cell__2qamw:last-child, .Deployments_cell2__rsLHD:last-child {
  border-right: none; 
}

.Deployments_checkbox__ccPtZ {
  margin: -0.1rem 0rem -1.2rem 0rem !important;
  padding: -20px !important;
  border: 0px solid red;
}


.Deployments_title__3GjXS {
  width: 65rem;
  margin: 1.2rem auto 0.2rem auto;

  border: 0px solid red;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  padding: 1rem;
  box-sizing: border-box;
  font-weight: 500;
}

.Deployments_baloon__2pBV4 {
  width: 14rem;
  background-color: white;
  padding: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.85rem;
  -webkit-transform: translate(-50%, -150%);
          transform: translate(-50%, -150%);
  box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(50,50,50,0.5);
  color: #555;
  font-weight: 400;
  text-align: left;
  border-radius: 0.4rem;
  box-sizing: border-box;
}

.Deployments_baloon__2pBV4:before {
  content:"";
  position:absolute;
  top:99%;
  right:45%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
}

.Deployments_marker__GT-KH {
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
}

.Deployments_TimeSeriesCard__FJBaM {
  display:inline-block;
  max-height: 500px;
  width: 90%;
  border: 0px solid green;
  /* margin: 20px; */
}

@media only screen and (min-width: 1281px) {
  .Deployments_TimeSeriesCard__FJBaM {
    width: 45%;
  }
}
.queryControls_SearchField__3lhtB, .queryControls_InputField__3YJzA, .queryControls_datePicker__2b-Ok {
  background-color: inherit;
  border: 1px solid lightgrey;
  min-height: 1.7rem;
  text-align: left;
  padding: 0rem 0.5rem;
  /* font-size: 0.95rem;  */
  font-size: small;
  /* font-family: 'Open Sans', sans-serif; */
}

.queryControls_datePicker__2b-Ok {
  width: 10.8rem;
  padding: 0rem 0rem 0rem 0.3rem;
  border-radius: 0.35rem;
  color: gray;
}

.queryControls_CheckBox__2Gh3Q {
  vertical-align: middle;
  margin: 2px 5px 0px 0px;
  width: 1rem;
  background-color: #2196F3;

}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: gray;
}
::-moz-placeholder { /* Firefox 19+ */
  color: gray;
}
:-ms-input-placeholder { /* IE 10+ */
  color: gray;
}
:-moz-placeholder { /* Firefox 18- */
  color: gray;
}

.queryControls_InputField__3YJzA {
  border-radius: 0.35rem;
  max-width: 160px;
  color: gray;
}

.queryControls_Select__2jOCF {
  border-radius: 0.35rem;
  max-width: 145px;
  color: gray;
}

.queryControls_SearchField__3lhtB:hover, .queryControls_datePicker__2b-Ok:hover, .queryControls_SearchField__3lhtB:focus, .queryControls_InputField__3YJzA:hover, .queryControls_InputField__3YJzA:focus {
  background-color:#dfdfdf;
  color: black;
}

input {
  font-family: "Roboto";
}


.queryControls_SearchButton__1elhw {
  position: absolute;
  top:-200px;
  border-radius: 20px;
  border: 0px;
  background-color:lightgray;
  font:inherit;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
  margin-left: 0px;
  visibility: hidden;
}

li .queryControls_react-datepicker__time-list-item__1AF17, .queryControls_react-datepicker__time-list-item--selected__2gYz4 {
  color: green;
}


@media only screen and (max-width: 1000px) {
  .queryControls_InputField__3YJzA {
    border-radius: 0.35rem;
    max-width: 110px;
    color: gray;
  }
  
}
.Card_card__2_6du {
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  border: 0px solid red;
  display: inline-block;
  background-color: transparent;
  width: auto;
}

.Card_cardContent__UeCC3 {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  cursor: default;
  background-color: #f7f7f7;
  background-clip: padding-box;
  border: 1px solid lightgrey;
  border-radius: .3rem;
  outline: 0;
  box-shadow: 0px 0px 20px 0px #cccccc;
  box-sizing: content-box;
}

.Card_cardHeader__2IgGB {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .0em .5em;
  border: 1px solid #808080;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: #808080;
  color: black;
  vertical-align: "middle";
  position: relative;
}

.Card_heading__1xDks {
  margin: 0.5em;
  font-size: 1.0em;
  color: white; /* black; */
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.01em;
  font-weight: 300;
  white-space: nowrap;      /*keep text on one line */
  overflow: hidden;         /*prevent text from being shown outside the border */
  text-overflow: ellipsis;  /*cut off text with an ellipsis*/
}

.Card_close__2VVb6 {
  padding: 0em 0.2em;
  background-color: transparent;
  border: 0;
  float: right;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1em;
  color: #fff;
  text-shadow: 0 1px 0 #666;
  opacity: 0.8;
  cursor: pointer;
}

.Card_close__2VVb6:hover {
  text-shadow: 0 1px 0 #000;
  opacity: 1; 
}

/* .cardDialog.cardNotify .cardBody {
  padding-top: 10rem;
  color: #616161;
} */

.Card_cardBody__PoiOK {
  position: relative;
  flex: 1 1 auto;
  padding: .7em 1em;
  font-family: "Roboto", sans-serif;
}

.Card_cardFooter__1ai7o {
  align-content: center;
  position: relative;
  border-top: 1px solid #dee2e6;
  flex: 1 1 auto;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.9em;
  padding: 0.7em 1em;
  max-height: 35px;
  color: #777;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3em;
  background-color: #efefef;
}

.tile_visibleBox__16Brm {
  width: 14rem; 
  height:14rem;
  box-shadow: 0rem 0rem 1rem  rgba(0,0,0,0.3);
  padding: 0rem;
  margin: 1rem;  
  overflow-y: hidden;
  flex-shrink: 0;
  border-radius: 0rem;;
}

.tile_primary_content__2y7xh {
  display: flex;
  flex-direction: column;
  width:14rem;
  height: 28rem;
  overflow: hidden;
  transition: all 0.3s ease-out;
}

.tile_primary_content__2y7xh:hover {
  display: flex;
  flex-direction: column;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: all 0.3s ease-out;
}

.tile_image__3JFq0 {
  height: 30%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  pointer-events: none;
}

.tile_title__20RN1 {
  font-size:0.9rem;
  font-weight:600; 
  line-height: 1.2rem; 
  border: 0px solid green; 
  margin: 0rem; 
  padding: 0.5rem 1rem;
  height: 20%;
  box-sizing: border-box;
  display:flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: #484F57;
  color: white;
  font-family: "Roboto-Light";
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.tile_description__3IZoO {
  font-size:0.8rem;
  font-weight:100; 
  line-height: 1rem; 
  text-align: left;
  background-color: #484f57;
  color: white;
  margin: 0rem; 
  padding: 1rem;
  box-sizing: border-box;
  height:30%;
  font-family: "Roboto-Light";
  display:flex;
  align-items: center;

  /* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

.tile_exploreBtn__3o1Dp {
  text-shadow: none;
  font-family: Roboto;
  font-size: 0.8rem;
  padding: 0.6rem 1.5rem;
  box-sizing: border-box;
  margin: auto;
  border: 0px solid #FAB700;
  background-color: #253343;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  color: rgb(238, 237, 237);
}

.tile_exploreBtn__3o1Dp:hover {
  transition: all 0.3s;
  background-color: #FAB700;
  color: #23303D;
}
.FAQs_pageContent__2hhvc {
  padding: 0rem;
}

.FAQs_mainContainer__2pVLJ {
  margin: 0rem auto;
  text-align: justify;
  min-width: 500px; 
  max-width: 800px; 
  padding: 0rem 5.8rem;
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-size: 1rem;
  line-height: 1.4rem;
}

.FAQs_question__3PR4W {
  background-color: #E4E6E8;
  border: 1px grey dashed;
  padding: 1rem 2rem;
  margin: 0.4rem 0rem 0.4rem 0rem;
  font-weight: 400;
  text-align: left;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.FAQs_question__3PR4W:hover {
  background-color: #d8dadb;
  transition: all 0.2s ease-in-out;
}

.FAQs_answerHide__3bzBw {
  font-family: "Roboto";
  max-height: 0rem;
  transition: all 0.3s;
  overflow-y: hidden;
  padding: 0rem 1rem 0rem 1rem;
  margin-bottom: 0rem;
}

.FAQs_answerShow__CUWCr {
  font-family: "Roboto";
  max-height: 10rem;
  transition: all 0.6s;
  overflow-y: hidden;
  padding: 0rem 1rem 0rem 1rem;
  margin-bottom: 0rem;
}

.FAQs_arrowRight__31_pQ, .FAQs_arrowDown__6iS1x {

  width: 0; 
  height: 0; 
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-left: 0.5rem solid grey;
  -webkit-transform: translate(-1.1rem, 0rem);
          transform: translate(-1.1rem, 0rem);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.FAQs_arrowDown__6iS1x {
  transform: translate(-1.1rem, 0.15rem) rotate(90deg);
  -webkit-transform: translate(-1.1rem, 0rem) rotate(90deg);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}


@media only screen and (max-width: 1000px) {
  .FAQs_pageContent__2hhvc {
    padding-top: 3rem;
    width: 100%;
  }

  .FAQs_mainContainer__2pVLJ {
    margin: 0rem auto;
    text-align: justify;
    min-width: 80%; 
    max-width: 90%; 
    padding: 0rem;
    font-family: "Open Sans", sans-serif; /* 'NexaLight' */
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .FAQs_question__3PR4W {
    margin: 1rem 0rem 0.4rem 0rem;
    font-weight: 600;
    text-align: left;
    font-size: 1.1rem;
    cursor: pointer;
    transition: margin 0.7s ease-in-out;
  }
}
.API_pageContent__kEYup {
  padding: 0rem;
  width: 100%
}

.API_tc__2qWT3 {
  margin: 0rem auto;
  text-align: justify;
  max-width: 50rem; 
  padding: 0rem 5.8rem;
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-size: 1rem;
  line-height: 1.4rem;
}


@media only screen and (max-width: 1000px) {

  .API_pageContent__kEYup {
    padding-top: 3rem;
    width:100%;
  }

  .API_tc__2qWT3 {
    margin: 0rem auto;
    text-align: justify;
    min-width: 80%; 
    max-width: 90%; 
    padding: 0rem;
    font-family: "Open Sans", sans-serif; /* 'NexaLight' */
    font-size: 1rem;
    line-height: 1.4rem;
  }
}
.lbn_box__2nl5B {
  display: block;
	margin: auto;
  max-width: 65rem;
	box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
	padding: 0.5rem;
	box-sizing: border-box;
	background-color: #f8f8f8;
}

.lbn_header__Gj7Wm, .lbn_row__2qjdV, .lbn_showML__138ah {
  display: flex;
  align-items: top;
  border-collapse: collapse;
  cursor: default;
  justify-content: left;
  font-size: 0.85rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

.lbn_showML__138ah {
  justify-content: center;
  vertical-align: text-top;
  /* width: 7rem; */
  color: #008abd;
  font-weight: 600;
  margin: auto;
  padding: 0.2rem 0.1rem;
  border-radius: 0.3rem;
}

.lbn_showML__138ah:hover {
  background-color: rgb(236, 243, 248);
}

.lbn_header__Gj7Wm {
  font-weight: 600;
  border-bottom: 1px solid #aaa;
  text-transform: uppercase;
}

.lbn_row__2qjdV:last-child {
  border-bottom: none;
}

.lbn_cell__3hrcW, .lbn_cell2__33u7d, .lbn_checkbox__1TPai {
  display: inline;
  padding: 0.5rem 0.7rem;
  text-align: left;
  border-right:1px dotted lightgrey;
}

.lbn_cell2__33u7d, a {
  color: #008abd;
  cursor: pointer;
  text-decoration: none;
}

.lbn_cell__3hrcW:last-child, .lbn_cell2__33u7d:last-child {
  border-right: none; 
}

.lbn_checkbox__1TPai {
  margin: -0.1rem 0rem -1.2rem 0rem !important;
  padding: -20px !important;
  border: 0px solid red;
}


.lbn_title__2YeTr {
  width: 65rem;
  margin: 1.2rem auto 0.2rem auto;

  border: 0px solid red;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  padding: 1rem;
  box-sizing: border-box;
  font-weight: 500;
}

.lbn_baloon__1kMxK {
  width: 14rem;
  background-color: white;
  padding: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.85rem;
  -webkit-transform: translate(-45%, -107%);
          transform: translate(-45%, -107%);
  box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(50,50,50,0.5);
  color: #555;
  font-weight: 400;
  text-align: left;
  border-radius: 0.4rem;
  box-sizing: border-box;
}

.lbn_TimeSeriesCard__ntXzD {
  display:inline-block;
  max-height: 500px;
  width: 90%;
  border: 0px solid green;
  /* margin: 20px; */
}

@media only screen and (min-width: 1281px) {
  .lbn_TimeSeriesCard__ntXzD {
    width: 45%;
  }
}
.Deployments_instructions__Wb6hA {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: medium;
  border-radius: 1rem;
  background-color: rgba(255,255,255,0.95);
  box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
  font-family: 'Roboto', sans-serif;
  color: rgba(80,80,80,1);
  width: min(80%, 30rem);
  box-sizing: border-box; 
}

.Deployments_info__1ZxFW {
  position: absolute;
  right: 2rem;
  top: 3rem;
  font-size: large;
  border-radius: 50%;
  background-color: rgb(245,245,245);
  box-shadow: 0px 0px 0.5rem rgba(0,0,0,0.5);
  font-family: 'Roboto', sans-serif;
  color: rgba(80,80,80,1);
  width: 3rem;
  height: 3rem;
  box-sizing: border-box; 
}

.Deployments_questionMark__2edKz {
  width: 3rem;
}



 .Deployments_box__1bYdb {
    display: block;
    margin: auto;
  }


  .Deployments_questionnaire__1jP1g, .Deployments_questionnaireUp__qeyki {
    background-color: rgba(245,245,245,1);
    margin: auto;
    width: 100%;
    height:50%;
    border: 0px solid green;
    position: absolute;
    bottom: 0%;
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    padding: 4% 7% 7% 7%;
    box-sizing: border-box;
    font-family: "Roboto";
    font-size: large;
    border-radius: 1rem 1rem 0rem 0rem;
    visibility: hidden;
  }

  .Deployments_questionnaireUp__qeyki {
    visibility: visible;
    height: 50%;
  }


  .Deployments_pageTitle__2l36c {
    color: white;
    font-family: 'Fedra',sans-serif;
    font-size: smaller;
    word-wrap: none;
    font-weight: 400;
    position: absolute;
    top: 0%;
    right: 0%;
    background-color: #23303dbb;
    padding: 0.2rem 0.4rem;
    border-radius: 0% 0% 0% 0.3rem;
  }

  .Deployments_pageSubtitle__2nc5P {
    color: #23303d; /*#c4c4c4;*/
    font-family: 'Fedra',sans-serif;
    font-size:1.25rem;
    word-wrap: none;
    font-weight: 400;
    position: absolute;
    top: 6%;
    left: 30%;
  }


  .Deployments_checkbox__3GsRG {
    margin: -0.1rem 0rem -1.2rem 0rem !important;
    padding: -20px !important;
    border: 0px solid red;
  }

  .Deployments_select__p1reb, .Deployments_textBox__2v2Ai{
    font-family: "Roboto";
    font-size: normal;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
    border: 1px solid lightgrey;
    width: 100%;
    overflow: hidden;
		text-overflow: ellipsis;
    margin-bottom: 1rem;
  }

  .Deployments_title__Frxwm {
    width: 65rem;
    margin: 1.2rem auto 0.2rem auto;

    border: 0px solid red;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    padding: 1rem;
    box-sizing: border-box;
    font-weight: 500;
  }

  .Deployments_baloon__3vKhg {
    width: 14rem;
    background-color: white;
    padding: 10rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.85rem;
    -webkit-transform: translate(-46%, -107%);
            transform: translate(-46%, -107%);
    box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(50,50,50,0.5);
    color: #555;
    font-weight: 400;
    text-align: left;
    /* border-radius: 0.4rem; */
    box-sizing: border-box;
  }

  .Deployments_baloon__3vKhg:before {
    content:"";
    position:absolute;
    top:99%;
    right:45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }

  .Deployments_questionIcon__d4GFh {
    width:3rem;
    margin-right: 0.7rem;
  }

  .Deployments_question__1nH5V {
    text-align: left;
    font-size: medium;
    border: 0px solid green;
  }

  .Deployments_questionBox__2gaoh {
    border: 0px solid red;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .Deployments_buttonSection__28lp8 {
    display: flex;
    position: absolute;
    bottom: 2rem;
    width: 84%;
  }

  .Deployments_instructions__Wb6hA {
    position: absolute;
    border: 0px solid red;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: medium;
    border-radius: 1rem;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 80%;
    box-sizing: border-box; 
  }






/* ===================================================== */

@media only screen and  (orientation: portrait) and (min-width: 1001px) {

  .Deployments_questionnaire__1jP1g, .Deployments_questionnaireUp__qeyki {
    background-color: rgba(245,245,245,1);
    margin: auto;
    width: 90%;
    height: calc(30% + 2rem);
    border: 0px solid green;
    position: relative;
    bottom: calc(30% + 2rem);
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    padding: 7% 7% 7% 7%;
    box-sizing: border-box;
    font-family: "Roboto";
    font-size: large;
    border-radius: 1rem 1rem 0rem 0rem;
    visibility: hidden;
  }

  .Deployments_questionnaireUp__qeyki {
    visibility: visible;
  }
}


@media only screen and  (orientation: landscape) and (min-width: 1001px){

  .Deployments_questionnaire__1jP1g, .Deployments_questionnaireUp__qeyki {
    background-color: rgba(245,245,245,1);
    margin: auto;
    width: calc(min(40%, 30rem));
    height: 60%;
    border: 0px solid green;
    position: absolute;
    top: 50%;
    right: 7rem;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    padding: 2% 2.5%;
    box-sizing: border-box; 
    font-family: "Roboto";
    font-size: large;
    border-radius: 1rem 1rem 1rem 1rem;
    justify-content: center;
    visibility: hidden;
  }

  .Deployments_questionnaireUp__qeyki {
    visibility: visible;
  }
  
  .Deployments_select__p1reb, .Deployments_textBox__2v2Ai{
    font-family: "Roboto";
    font-size: medium;
    border-radius: 0.4rem;
    padding: 0.1rem 0.5rem;
    border: 1px solid lightgrey;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1rem;
  }

  .Deployments_buttonSection__28lp8 {
    display: flex;
    position: absolute;
    bottom: 2rem;
    border: 0px solid red;
    width: 85%;
  }

  .Deployments_info__1ZxFW {
    position: absolute;
    left: 2rem;
    top: calc(100% - 2rem);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    font-size: large;
    border-radius: 50%;
    background-color: rgb(245,245,245);
    box-shadow: 0px 0px 0.5rem rgba(0,0,0,0.5);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 3rem;
    height: 3rem;
    padding: 0.2rem;
    box-sizing: content-box; 
  }

  .Deployments_questionMark__2edKz {
    width: 3rem;
  }

  .Deployments_questionBox__2gaoh {
    border: 0px solid red;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 1rem;
  }

  .Deployments_question__1nH5V {
    text-align: center;
    font-size: medium;
    border: 0px solid green;
  }

}



@media only screen and  (orientation: portrait) and (max-width: 1000px){

  .Deployments_info__1ZxFW {
    position: absolute;
    border: 0px solid red;
    left: 1rem;
    top: calc(100% - 2.5rem);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    font-size: large;
    border-radius: 50%;
    background-color: rgb(245,245,245);
    box-shadow: 0px 0px 0.5rem rgba(0,0,0,0.5);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 2.5rem;
    height: 2.5rem;
    box-sizing: border-box; 
    padding: 0.2rem;
    box-sizing: content-box;     
  }

  .Deployments_questionMark__2edKz {
    width: 2.5rem;
  }

}



@media only screen and  (orientation: landscape) and (max-width: 1000px){

  .Deployments_questionnaire__1jP1g, .Deployments_questionnaireUp__qeyki {
    background-color: rgba(245,245,245,1);
    margin: auto;
    width: 40%;
    height: 100%;
    border: 0px solid green;
    position: absolute;
    bottom: 0%;
    right: 0rem;
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    padding: 2% 2.5%;
    box-sizing: border-box; 
    font-family: "Roboto";
    font-size: large;
    border-radius: 1rem 0rem 0rem 1rem;
    justify-content: center;
    visibility: hidden;
  }

  .Deployments_questionnaireUp__qeyki {
    visibility: visible;
  }
  
  .Deployments_select__p1reb, .Deployments_textBox__2v2Ai{
    font-family: "Roboto";
    font-size: normal;
    border-radius: 0.4rem;
    padding: 0.1rem 0.5rem;
    border: 1px solid lightgrey;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1rem;
  }

  .Deployments_buttonSection__28lp8 {
    display: flex;
    position: absolute;
    bottom: 2rem;
    border: 0px solid red;
    width: 85%;
  }

  .Deployments_info__1ZxFW {
    position: absolute;
    left: 1.5rem;
    top: calc(100% - 2.5rem);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    border-radius: 50%;
    background-color: rgb(245,245,245);
    box-shadow: 0px 0px 0.5rem rgba(0,0,0,0.5);
    width: 2.5rem;
    height: 2.5rem;
    box-sizing: content-box; 
    padding: 0.2rem;
    font-size: large;
  }

  .Deployments_questionMark__2edKz {
    width: 2.5rem;
  }

  .Deployments_questionIcon__d4GFh {
    width:3rem;
    margin: 0.5rem auto;
  }

  .Deployments_questionBox__2gaoh {
    border: 0px solid red;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 1rem;
  }

  .Deployments_question__1nH5V {
    text-align: center;
    font-size: medium;
    border: 0px solid green;
  }

  .Deployments_instructions__Wb6hA {
    position: absolute;
    border: 0px solid red;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: medium;
    border-radius: 1rem;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 75%;
    box-sizing: border-box; 
  }

}

.barChart_bar__3HTpt {
  display: flex;
  position: absolute;
  overflow-x: visible;
  white-space: nowrap;
}

.barChart_closeCross__KiytQ {
  position: absolute;
  top: 0.5rem;
  right: 0.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  line-height: 1.5rem;
  color: grey;
}

.barChart_chartArea__cBikU {
  display:flex;
  flex-flow: column nowrap;
  position: absolute;
  align-items: flex-start;
  top: 50%;
  right: 50%;
  background-color: white;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  box-sizing: border-box;
  transition: opacity linear 0.3s, -webkit-transform  linear 0.3s;
  transition: transform  linear 0.3s, opacity linear 0.3s;
  transition: transform  linear 0.3s, opacity linear 0.3s, -webkit-transform  linear 0.3s;
  border-left: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
}

.barChart_chartBox__3sLW3 {
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  align-items: flex-end;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0.5rem rgba(0,0,0,0.5);
  width: calc(min(80%, 30rem));
  height: calc(min(80%, 20rem));
  box-sizing: border-box;
  overflow: hidden;
}
.PerceptualMap_closeCross__3Ul0Y {
  position: absolute;
  top: 0.1rem;
  right: 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: grey;
}

.PerceptualMap_info__1NNn6 {
  position: absolute;
  border: 0px solid red;
  right: 2rem;
  top: calc(100% - 2rem);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  font-size: x-large;
  border-radius: 50%;
  background-color: rgb(245,245,245);
  box-shadow: 0rem 0rem 0.5rem rgba(0,0,0,0.5);
  font-family: 'Roboto', sans-serif;
  color: rgba(80,80,80,1);
  width: 3rem;
  height: 3rem;
  box-sizing: border-box;
}

.PerceptualMap_graph__1vt59 {
  width: 3rem;
}


@media only screen and (min-width: 1001px) {
  .PerceptualMap_mapContainer__2ojbB {
    height: calc(100% - 4rem);
    width: calc(100% - 8rem);
    border: 0.3rem solid rgb(248, 248, 248);
    margin: 2rem 4rem;
    position: absolute;
    touch-action: none;
    pointer-events: all;
    box-sizing: border-box;
    box-shadow: 0rem 0rem 1.25rem rgba(0, 0, 0, 0.2);
  }

  .PerceptualMap_baloon__1s_xC {
    -webkit-transform: translate(-50%, calc(-100% - 1.1rem));
            transform: translate(-50%, calc(-100% - 1.1rem));
    background-color: white;
    padding: 1rem 0.2rem 0.5rem 0.2rem;
    font-family: 'Open Sans', sans-serif;
    font-size: small;
    box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(0, 0, 0, 0.3);
    color: #555;
    font-weight: 400;
    text-align: left;
    border-radius: 0.4rem;
    box-sizing: border-box;
    position: relative;
    max-width: 14rem;
  }

  .PerceptualMap_baloon__1s_xC:before {
    content: '';
    position: absolute;
    top: 98%;
    right: 50%;
    -webkit-transform: translate(50%, 0%);
            transform: translate(50%, 0%);
    width: 0;
    height: 0;
    border-left: 0.63rem solid transparent;
    border-right: 0.63rem solid transparent;
    border-top: 0.63rem solid white;
  }

  .PerceptualMap_selectMetric__3gvka {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    bottom: 4rem;
    font-family: 'Roboto';
    font-size: normal;
    padding: 0.4rem 1rem;
    border: 2px solid rgb(140, 192, 244);
    border-radius: 0.4rem;
    background-color: white;
    /* box-shadow: 0px 0px 0.5rem 0.2rem rgba(0,0,0,0.3); */
  }

  .PerceptualMap_showLegend__13TjY {
    position: absolute;
    bottom: 4rem;
    right: 6rem;
    width: 1rem;
    height: 1rem;
    border: 1px solid green;
    padding: 1.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 0px solid lightgrey;
    box-shadow: 0px 0px 0.5rem 0.2rem rgba(0, 0, 0, 0.3);
  }

  .PerceptualMap_legend__E7aOf {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    bottom: 9rem;
    right: 6rem;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    background-color: white;
    border-radius: 0.4rem;
    padding: 1.5rem 0.8rem 0.8rem 0.8rem;
    width: 13rem;
    font-size: normal;
    border: 0px solid lightgrey;
    box-shadow: 0px 0px 0.5rem 0.2rem rgba(0, 0, 0, 0.3);
  }

  .PerceptualMap_info__1NNn6 {
    position: absolute;
    left: 2rem;
    top: calc(100% - 2rem);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    font-size: x-large;
    border-radius: 50%;
    background-color: rgb(245,245,245);
    box-shadow: 0rem 0rem 0.5rem rgba(0,0,0,0.5);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 3rem;
    height: 3rem;
    padding: 0.2rem;
    box-sizing: content-box;
  }

  .PerceptualMap_graph__1vt59 {
    width: 3rem;
  }

}


@media only screen and (max-width: 1000px) {
  .PerceptualMap_mapContainer__2ojbB {
    height: 100%;
    width: 100%;
    border: 0px solid rgb(248, 248, 248);
    margin: 0rem;
    position: absolute;
    touch-action: none;
    pointer-events: all;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }

  .PerceptualMap_baloon__1s_xC {
    background-color: white;
    padding: 1rem;
    font-family: 'Open Sans', sans-serif;
    font-size: small;
    -webkit-transform: translate(-50%, calc(-100% - 1.1rem));
            transform: translate(-50%, calc(-100% - 1.1rem));
    box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(0, 0, 0, 0.3);
    color: #555;
    font-weight: 400;
    text-align: left;
    border-radius: 0.4rem;
    box-sizing: border-box;
    position: relative;
  }

  .PerceptualMap_baloon__1s_xC:before {
    content: '';
    position: absolute;
    top: 98%;
    right: 50%;
    -webkit-transform: translate(50%, 0%);
            transform: translate(50%, 0%);
    width: 0;
    height: 0;
    border-left: 0.63rem solid transparent;
    border-right: 0.63rem solid transparent;
    border-top: 0.63rem solid white;
  }

  .PerceptualMap_selectMetric__3gvka {
    position: absolute;
    bottom: 1.4rem;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    font-family: 'Roboto';
    font-size: normal;
    padding: 0.4rem 1rem;
    border: 2px solid rgb(140, 192, 244);
    border-radius: 0.4rem;
    width: calc(max(14rem, calc(100% - 16rem)));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: white;
  }

  .PerceptualMap_showLegend__13TjY {
    position: absolute;
    bottom: 1.4rem;
    right: 2rem;
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid lightgrey;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .PerceptualMap_legend__E7aOf {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    bottom: 5rem;
    right: 2rem;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    background-color: white;
    border-radius: 0.4rem;
    padding: 1.5rem 0.8rem 0.8rem 0.8rem;
    width: 11rem;
    font-size: small;
    border: 1px solid lightgrey;
  }

  .PerceptualMap_info__1NNn6 {
    position: absolute;
    border: 0px solid red;
    left: 1rem;
    top: calc(100% - 2.5rem);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    font-size: x-large;
    border-radius: 50%;
    background-color: rgb(245,245,245);
    box-shadow: 0rem 0rem 0.5rem rgba(0,0,0,0.5);
    font-family: 'Roboto', sans-serif;
    color: rgba(80,80,80,1);
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.2rem;
    box-sizing: content-box;
  }

  .PerceptualMap_graph__1vt59 {
    width: 2.5rem;
  }

}

.ButtonBar_customButton__3RJoM, .ButtonBar_active__3KWpx {
  min-width: 4rem;
  width: 5.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.ButtonBar_active__3KWpx{
  background-color: rgb(35,48,61);
  color: white;
  }
.CreateEditDevices_customInput__2xYOz, .CreateEditDevices_customInput2__vK7m7 {
  font-size: 0.9rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.4rem;
  background-color: white;
  border: 1px solid #bfbfbf;
  margin: 0rem 0.5rem;
  /* width: 12rem; */
  /* max-width: 13rem; */
  max-height: 2rem;
  height: 1.5rem;
  box-sizing: content-box;
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
} 

.CreateEditDevices_customInput2__vK7m7 {
  border: none;
}

option {
  font-size: 0.85rem;
  font-family: "Open Sans", sans-serif; /*'NexaLight' */
}

.CreateEditDevices_row__3iqzC, .CreateEditDevices_row2__2xBEU {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0rem auto;
  border-bottom: 1px solid lightgrey;
  padding: 0.4rem 1rem;
  width: 70rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
}

.CreateEditDevices_row2__2xBEU {
  color: #555555;
}

.CreateEditDevices_row2__2xBEU:hover  {
  color: #008abd;
  cursor: pointer;
}

.CreateEditDevices_row__3iqzC:first-child, .CreateEditDevices_row2__2xBEU:first-child {
  border-top: 1px solid lightgrey;
  margin-top: 2rem;
}
.CreateEditDevices_row__3iqzC:last-child, .CreateEditDevices_row2__2xBEU:last-child {
  margin-bottom: 2rem;
}

.CreateEditDevices_title__ODJui {
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-family: 1.5rem;
  font-weight: 600;
}

.CreateEditDevices_label__2fW55 {
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-size: 2rem;
}
.CheckBoxArea_checkBoxArea__3Ws6K {
  flex-flow: column;
  border: 1px solid #bfbfbf;
  justify-content: flex-start;
  justify-items: left;
  height: 6.4rem;
  overflow-y: scroll;
  background-color: #fff;
  width: 20rem;
  padding: 0.3rem 0.5rem;
  margin: 0rem 0.5rem;
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif; /*'NexaLight' */
}
