.pageContent {
  padding: 0rem;
}

.tc {
  margin: 0rem auto;
  text-align: justify;
  min-width: 500px; 
  max-width: 800px; 
  padding: 0rem 5.8rem;
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-size: 1rem;
  line-height: 1.4rem;
}

li {
  margin: 1rem 0rem 1rem 0rem;
  /* font-family: 'NexaLight',sans-serif; */
}


@media only screen and (max-width: 1000px) {
  .pageContent {
    padding-top: 3rem;
  }

  .tc {
    margin: 0rem auto;
    text-align: justify;
    min-width: 80%; 
    max-width: 90%; 
    padding: 0rem;
    font-family: "Open Sans", sans-serif; /* 'NexaLight' */
    font-size: 1rem;
    line-height: 1.4rem;
  }
}