.customInput, .customInput2 {
  font-size: 0.9rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.4rem;
  background-color: white;
  border: 1px solid #bfbfbf;
  margin: 0rem 0.5rem;
  /* width: 12rem; */
  /* max-width: 13rem; */
  max-height: 2rem;
  height: 1.5rem;
  box-sizing: content-box;
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
} 

.customInput2 {
  border: none;
}

option {
  font-size: 0.85rem;
  font-family: "Open Sans", sans-serif; /*'NexaLight' */
}

.row, .row2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0rem auto;
  border-bottom: 1px solid lightgrey;
  padding: 0.4rem 1rem;
  width: 70rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
}

.row2 {
  color: #555555;
}

.row2:hover  {
  color: #008abd;
  cursor: pointer;
}

.row:first-child, .row2:first-child {
  border-top: 1px solid lightgrey;
  margin-top: 2rem;
}
.row:last-child, .row2:last-child {
  margin-bottom: 2rem;
}

.title {
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-family: 1.5rem;
  font-weight: 600;
}

.label {
  font-family: "Open Sans", sans-serif; /* 'NexaLight' */
  font-size: 2rem;
}