.banner, .banner2, .banner3 {
  margin: 1rem 5rem;
  /* display: flex;
  vertical-align: middle;
  align-items: center; */
  background-color: rgb(252, 208, 208);
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  border: 1px solid red;
  max-height: 4rem;
  -webkit-transition: max-height 0.7s; /* For Safari 3.1 to 6.0 */
  transition: padding 0.5s, max-height 0.5s, margin 0.5s;
  overflow-y: hidden;
  box-sizing: border-box;
  /* color: red; */
}

.banner2 {
  margin: 0.3rem 5rem;
  padding: 0rem;
  border: 0px solid red;
  max-height: 0rem;
  -webkit-transition: max-height 0.7s; /* For Safari 3.1 to 6.0 */
  transition: padding 0.5s, max-height 0.5s, margin 0.5s;
}

.banner3 {
  background-color: rgb(255,225,129);
  border: 1px solid rgb(160, 141, 56);
}

.generalMap, .AQIndexMap {
  height: 50rem;
  margin: 1rem 5rem 2rem 5rem;
  border: 0.3rem solid rgb(248,248,248);
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
  position: relative;
  overflow: hidden;
  background-color: rgb(248,248,248);
}

.controls {
  position: absolute;
  top: 0rem;
  left: 1rem;
  padding: 1rem 0.3rem;
  margin: 0rem;
  display: flex;
  border: 0px solid lightgrey;
  border-radius: 0.3rem;
  box-sizing: border-box;
  align-items: center;
}

.AQIndexMap {
  border: 0rem solid rgb(248,248,248);
  height: 51rem;
}


.box {
  display: block;
  margin: auto;
  max-width: 65rem;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: #f8f8f8;
}

.header, .row, .showML {
  display: flex;
  align-items: top;
  border-collapse: collapse;
  cursor: default;
  justify-content: left;
  font-size: 0.85rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

.showML {
  justify-content: center;
  vertical-align: text-top;
  /* width: 7rem; */
  color: #008abd;
  font-weight: 600;
  margin: auto;
  padding: 0.2rem 0.1rem;
  border-radius: 0.3rem;
}

  .showML:hover {
    background-color: rgb(236, 243, 248);
  }

.header {
  font-weight: 600;
  border-bottom: 1px solid #aaa;
  text-transform: uppercase;
}

.row:last-child {
  border-bottom: none;
}

.cell, .cell2, .checkbox {
  display: inline;
  padding: 0.5rem 0.7rem;
  text-align: left;
  border-right: 1px dotted lightgrey;
}

.cell2, a {
  color: #008abd;
  cursor: pointer;
  text-decoration: none;
}

  .cell:last-child, .cell2:last-child {
    border-right: none;
  }

.checkbox {
  margin: -0.1rem 0rem -1.2rem 0rem !important;
  padding: -20px !important;
  border: 0px solid red;
}


.title {
  width: 65rem;
  margin: 1.2rem auto 0.2rem auto;
  border: 0px solid red;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  padding: 1rem;
  box-sizing: border-box;
  font-weight: 500;
}

.baloon {
  width: 14rem;
  background-color: white;
  padding: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.85rem;
  transform: translate(-50%, calc(-100% - 3rem));
  box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(50,50,50,0.5);
  color: #555;
  font-weight: 400;
  text-align: left;
  border-radius: 0.4rem;
  box-sizing: border-box;
  position: relative;
}

  .baloon:before {
    content: "";
    position: absolute;
    top: 99%;
    right: 45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }


.baloon2 {
  width: 14rem;
  background-color: white;
  padding: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.85rem;
  transform: translate(-50%, -130%);
  box-shadow: 0.3rem 0.6rem 0.8rem 0rem rgba(50,50,50,0.5);
  color: #555;
  font-weight: 400;
  text-align: left;
  border-radius: 0.4rem;
  box-sizing: border-box;
  position: relative;
}

  .baloon2:before {
    content: "";
    position: absolute;
    top: 99%;
    right: 45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }


.infoBox, .plotBox {
  position: relative;
  background-color: rgba(248,248,248,1);
  padding: 2rem 2rem;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  scroll-behavior: smooth;
  scroll-behavior: auto;
  overflow-y: scroll;
  border: 0px solid green;
  width: 15rem;
  pointer-events: all;
}

.plotBox {
  height: 100%;
  border: 0px solid green;
  flex-grow: 1;
}

.test {
  width: 15rem;
  height: 100%;
  background-color: rgba(248,248,248,1);
  padding: 1.5rem 1.2rem;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-behavior: auto;
  overflow-y: scroll;
  position: absolute;
  right: -0.3rem;
  top: 0rem;
  /* border: 1px solid red; */
}

.statDescription {
  height: 10rem;
  scroll-behavior: smooth;
  scroll-behavior: auto;
  overflow-y: scroll;
  color: #555;
  font-weight: 400;
  font-size: small;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  /* border: 1px solid green; */
}

.closeCross {
  opacity: 0.3;
}

  .closeCross:hover {
    opacity: 0.6;
  }

.infoBoxTitle {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(80,80,80);
  margin-top: 0.5rem;
}

.toggleBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: small;
  border: 0px solid lightgrey;
  border-radius: 1.5rem;
  margin: 0rem 0.35rem;
  padding: 0.2rem 0.8rem;
  background-color: #f5f5f5;
  /*box-shadow: 0.1rem 0.1rem 0.2rem rgba(0,0,0,0.4);*/
  box-shadow: 0rem 0rem 0.3rem rgba(0,0,0,0.5);
  border: 0px solid lightgrey;
}

  .toggleBtn:hover {
    border: 0px solid lightgrey;
    background-color: #ffffff;
  }


.ActiveTab, .InactiveTab {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3rem;
  width: 100%;
  height: 3rem;
  background-color: white;
  padding-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid white;
  border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-radius: 5px 5px 0px 0px;
}

.InactiveTab {
  background-color: #f8f8f8;
  color: grey;
}


.AQIndex {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px rgba(0,0,0,1);
  font-size: 1rem;
  font-weight: 600;
}

.plotBtn {
  height: 1.4rem;
  cursor: pointer;
  border-radius: 10%;
  padding: 0.3rem;
  background-color: #eee;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(220,220,220,1) rgba(248,248,248,1);
}

  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(248,248,248,1);
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(220,220,220,1);
    border-radius: 10px;
    border: 0px solid white;
  }

.clusterMarker {
  color: #fff;
  /*background: #1978c8;*/
  /*background-color: #23303D;*/
  background: rgba(90,90,90,0.6);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  transition: background 0.2s;
}
  .clusterMarker:hover {
    background: #1978c8;
    background:  rgba(110,110,110,1);
    transition: background 0.2s;
  }

  .infoContainer {
    display: flex;
    flex-flow: row;
    width: 90%;
    height: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0px solid red;
    pointer-events: none;
  }




@media only screen and (max-width: 1000px) {
  .infoBox, .plotBox {
    width: 100%;
    height: 50%;
    background-color: rgba(248,248,248,1);
    padding: 2rem 2rem;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    scroll-behavior: smooth;
    scroll-behavior: auto;
    overflow-y: scroll;
    position: absolute;
    left: 0rem;
    top: 50%;
    border-radius: 1rem 1rem 0rem 0rem;
    box-shadow: 0px 0px 1rem rgba(0,0,0,0.3);
    pointer-events: all;
  }

  .plotBox {
    width: 100%;
    height: calc(100% - 0rem);
    position: absolute;
    left: 0rem;
    top: 0rem;
    border-radius: 0rem;
    box-shadow: none;
    pointer-events: all;
  }

  .infoContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0rem;
    top: 0rem;
    transform: translateX(0%);
    border: 0px solid red;
    background-color: transparent;
    pointer-events: none;
  }
}
