.SearchField, .InputField, .datePicker {
  background-color: inherit;
  border: 1px solid lightgrey;
  min-height: 1.7rem;
  text-align: left;
  padding: 0rem 0.5rem;
  /* font-size: 0.95rem;  */
  font-size: small;
  /* font-family: 'Open Sans', sans-serif; */
}

.datePicker {
  width: 10.8rem;
  padding: 0rem 0rem 0rem 0.3rem;
  border-radius: 0.35rem;
  color: gray;
}

.CheckBox {
  vertical-align: middle;
  margin: 2px 5px 0px 0px;
  width: 1rem;
  background-color: #2196F3;

}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: gray;
}
::-moz-placeholder { /* Firefox 19+ */
  color: gray;
}
:-ms-input-placeholder { /* IE 10+ */
  color: gray;
}
:-moz-placeholder { /* Firefox 18- */
  color: gray;
}

.InputField {
  border-radius: 0.35rem;
  max-width: 160px;
  color: gray;
}

.Select {
  border-radius: 0.35rem;
  max-width: 145px;
  color: gray;
}

.SearchField:hover, .datePicker:hover, .SearchField:focus, .InputField:hover, .InputField:focus {
  background-color:#dfdfdf;
  color: black;
}

input {
  font-family: "Roboto";
}


.SearchButton {
  position: absolute;
  top:-200px;
  border-radius: 20px;
  border: 0px;
  background-color:lightgray;
  font:inherit;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
  margin-left: 0px;
  visibility: hidden;
}

li .react-datepicker__time-list-item, .react-datepicker__time-list-item--selected {
  color: green;
}


@media only screen and (max-width: 1000px) {
  .InputField {
    border-radius: 0.35rem;
    max-width: 110px;
    color: gray;
  }
  
}